import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import ArrowBorder from "GUI_MAIN/icons/ArrowBorder";
import IconButton from "GUI_MAIN/Elements/IconButton";

const ExpertSection = styled.div``;

const ExpertHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.mediumFontSize}`;

const IconWrapper = styled.div`
  transform: ${props => props.rotateArrow ? 'rotate(180deg)' : 'rotate(0deg)'};
  position: relative;
  cursor: pointer;
  min-width: ${props=> props.width? props.width : '20px'};
  max-width: ${props=> props.width? props.width : '20px'};
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    width:100%;
    height: auto;
  }`;

const ExpertName = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.mainDarkBlueColor};;
  color: ${props => props.theme.whiteColor};
  border-radius: 21px;
  padding: 3px 20px`;

@observer
export class AppointmentDetails extends React.PureComponent {
    render() {
        const {
            item,
            details
        } = this.props;
        return (
            <ExpertSection>
                <ExpertHeader>
                    { item.isParticipant && <ExpertName>Я участник</ExpertName> }
                    <IconButton
                        onClick={details.toggle}
                        icon={<ArrowBorder rotateArrow={details.show ? '180' : '0'}/>}
                        margin={'0 0 0 16px'}
                    />
                </ExpertHeader>
            </ExpertSection>
        )
    }
}
