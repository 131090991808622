import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import BigPatient from "GUI_MAIN/icons/BigPatient";
import { EOLocale } from "eo-locale";

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding-bottom: 20px;
`;

const NoItemText = styled.div`
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.bigFontSize};
  color: ${props => props.theme.mainRedColor};
  margin-bottom: 18px`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 128px;
  height: 128px;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 16px;
  background-color: rgba(209, 233, 250, 0.25);
  svg{
  width: 100%;
  height: auto;
  }
`;

@observer
export class ServiceRequestsError extends React.PureComponent {
    render() {
        return (
            <Wrapper>
                <IconWrapper>
                    <BigPatient/>
                </IconWrapper>
                <NoItemText><EOLocale.Text id={"TT022360"} /></NoItemText>
            </Wrapper>
        )
    }
}
