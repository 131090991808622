import React from "react";
import {inject, observer } from "mobx-react";
import styled from "styled-components";
import Preloader from "GUI_MAIN/Elements/Preloader";
import { ServiceRequestsError } from "./ServiceRequestsError";
import { ServiceRequestsEmpty } from "./ServiceRequestsEmpty";
import { ServiceRequestsTable } from "./ServiceRequestsTable";

import _get from "lodash/get";

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  background-color: white;
  height: 100%;
  width: 100%;
`;


@observer
export class ServiceRequestsContent extends React.PureComponent {


    requesterId = this.props.AuthStore.currentUserData.active_role_id;

    getContentComponent = (items, error) => {
        if( error ) {
            return <ServiceRequestsError/>;
        }
        if( ! items.length ) {
            return <ServiceRequestsEmpty createServiceRequest={this.props.createServiceRequest}/>;
        }
        return <ServiceRequestsTable items={items} redirect={this.props.redirect}/>;
    }

    getMyRequests = (serviceRequests) => {
      // return [serviceRequests[0]];
      return serviceRequests.filter(resource => {
            return resource.requester == this.requesterId;
        })
    }

    render() {
        const {
            error,
            items,
            loading
        } = this.props.serviceRequests;

        let serviceRequests = null;


        if ( loading ) {
            return <Preloader />;
        }

        serviceRequests = items;

        return (
            <ContentWrapper>
                { this.getContentComponent(serviceRequests, error) }
            </ContentWrapper>
        )
    }
}
