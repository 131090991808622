import React from 'react';
import { observer } from "mobx-react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import Scrollbar from "react-scrollbars-custom";
import { AppointmentItem } from "./AppointmentItem/index";

@observer
export class AppointmentsList extends React.PureComponent {
    render() {
        return (
            <>
                <Scrollbar noScrollX={true}>
                    { this.props.items.map(item => (
                        <AppointmentItem
                            item={item}
                            key={item.id}
                            createServiceRequest={this.props.createServiceRequest}/>
                    )) }
                </Scrollbar>
            </>
        )
    }
}

// <Scrollbars
//     autoHeight
//     autoHeightMax={'350px'}
//     autoHeightMin={'350px'}>
// </Scrollbars>
