import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { MonthDay } from "./MonthDay";

const DaysWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
`;

@observer
export class MonthDays extends React.PureComponent {
    render () {
        return (
            <DaysWrapper>
                { this.props.calendar.days.map(day => <MonthDay key={day.date} day={day} today={this.props.calendar.cursorDate}/> ) }
            </DaysWrapper>
        )
    }
}
