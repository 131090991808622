import { observable } from 'mobx';

export class TextFilter {

    /**
     * значение фильтра
     */
    @observable value = "";

    /**
     * установка значения фильтра
     */
    setValue = (value = "") => {
        this.value = value;
    }

}
