import _get from "lodash/get";

/**
 * ответ от сервера
 */
export class Response {

    /**
     * ответ от сервера
     */
    response = null;

    /**
     * на входе - ответ от сервера
     */
    constructor(response) {
        this.response = response;
    }

    /**
     * массив всех загруженных ресурсов
     */
    get resources() {
        return this.response.entry.map(entry => entry.resource);
    }

    /**
     * поиск ресурса по идентификатору и типу
     */
    findResource = (type, id) => {
        return this.resources.find(resource => resource.resourceType == type && resource.id == id);
    }

    /**
     * фильтрация ресурсов по типу
     */
    filterResources = (type) => {
        return this.resources.filter(({resourceType}) => resourceType == type);
    }

    /**
     * поиск ресурсов по типу по переданным ссылкам
     */
    filterResourcesByReferences = (type, references = []) => {
        return references.filter(({resourceType}) => resourceType == type).map(reference => {
            return this.findResource(type, reference.id);
        }).filter(resource => resource);
    }

}
