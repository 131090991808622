import { observable } from 'mobx';

export class OrderFilter {

    /**
     * ключ сортировки
     */
    @observable key = "appointment-date";
    
    /**
     * направление сортировки
     */
    @observable dir = "asc";

    /**
     * значение (составной ключ)
     */
    get value() {
        return `${this.key}-${this.dir}`;
    }

    /**
     * изменение
     */
    change = (key, dir) => {
        this.key = key;
        this.dir = dir;
    }

}
