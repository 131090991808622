import { observable } from 'mobx';

export class LimitFilter {

    /**
     * кол-во результатов на странице
     */
    @observable count = 15;

    /**
     * установка кол-ва результатов на странице
     */
    setCount = (value) => {
        this.count = parseInt(value);
    }

}
