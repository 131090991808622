import _get from "lodash/get";
import { observable } from "mobx";
import { ConditionMkb10CodesValueSet } from "MODEL_STORE/DataSource/Stores/ValueSets/ConditionMkb10CodesValueSet";

/**
 * задержка перед запросом
 */
const SEARCH_REQUEST_TIMEOUT = 500;

/**
 * фильтр по кодам МКБ-10
 */
export class Mkb10Filter {

    /**
     * таймаут перед запросом
     */
    @observable timeout = null;

    /**
     * справочник кодов МКБ-10
     */
    @observable valueset = new ConditionMkb10CodesValueSet();

    /**
     * код выбранного концепта
     */
    @observable value = "";

    /**
     * загрузка концептов с фильтрацией по поисковой строке
     */
    loadConcepts = (filter) => {
        this.valueset.search(filter);
    }

    /**
     * сброс результатов поиска концептов
     */
    resetOptions = () => {
        if (!this.value.length) {
            this.valueset.concepts = [];
        }
    }

    /**
     * выбранное значение кода МКБ-10 пациента для React-Select
     */
    get option() {
        return this.valueset.options.find(option => option.value == this.value) || "";
    }

    /**
     * возможные значения кода МКБ-10 пациента для React-Select
     */
    get options() {
        return this.valueset.options.filter(option => option.value != "C97");
    }

    /**
     * установка кода МКБ-10 пациента
     */
    setOption = (selected) => {
        this.value = _get(selected, "value", "");
        this.resetOptions();
        this.setLocalStorage();
    }

    /**
     * поиск кодов МКБ-10 по названию
     */
    search = (filter) => {
        if( ! filter || filter.length < 2) {
            return filter;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.loadConcepts(filter), SEARCH_REQUEST_TIMEOUT);
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
