import { observable } from 'mobx';

export class OrderFilter {

    /**
     * направление сортировки
     */
    @observable direction = "asc";
    /**
     * поле сортировки
     */
    @observable key = "composition-date";

    // /**
    //  * изменение направления сортировки
    //  */
    // toggleDirection = () => {
    //     this.direction = (this.direction == "asc") ? "desc" : "asc";
    // }

    change = (key, direction) => {
        this.key = key;
        this.direction = direction;
    }

}
