import { observable } from 'mobx';

export class PaginationFilter {

    /**
     * текущая страница
     */
    @observable page = 1;

    /**
     * кол-во страниц с результатами
     */
    @observable pages = 1;

    /**
     * установка текущей страницы
     */
    setPage = (value) => {
        const page = parseInt(value);
        if(page > 0 && page <= this.pages) {
            this.page = page;
        }        
    }

    /**
     * установка кол-ва страниц с результатами
     */
    setPages = (value) => {
        this.pages = parseInt(value);
    }

}
