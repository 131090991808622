const Paths = {
    patient : {
        list : {
            path : () => `/patient/list`,
            mask : () => `/patient/list`,
        },
    },
    servicerequest : {
        list : {
            path : () => `/servicerequest/list`,
            mask : () => `/servicerequest/list`,
        },
        create : {
            path : () => `/servicerequest/create`,
            mask : () => `/servicerequest/create`,
        },
        modify : {
            path : (id) => `/servicerequest/create/${id}`,
            mask : () => `/servicerequest/create/(:id)`,
        },
        edit : {
            path : (id) => `/servicerequest/${id}`,
            mask : () => `/servicerequest/(:id)`,
        },
    },

    protocol : {
        list : {
            path: () => `/protocol/list`,
            mask: () => `/protocol/list`,
        },
        edit : {
            path : (protocolId) => `/protocol/${protocolId}`,
            mask : () => `/protocol/(:protocolId)`,
        },
        pdf : {
            path : (protocolId) => `/protocol/${protocolId}/pdf`,
            mask : () => `/protocol/(:protocolId)/pdf`,
        },
        document : {
            path : (protocolId) => `/protocol/${protocolId}/document`,
            mask : () => `/protocol/(:protocolId)/document`,
        },
    },

    appointment : {
        list : {
            path : () => `/appointment/list`,
            mask : () => `/appointment/list`,
        },
        create : {
            path : () => `/appointment/create`,
            mask : () => `/appointment/create`,
        },
        novalues : {
            path : () => `/appointment/noValues`,
            mask : () => `/appointment/noValues`,
        },
        edit : {
            path : (id) => `/appointment/${id}/edit`,
            mask : () => `/appointment/(:id)/edit`,
        },
        show : {
            path : (id) => `/appointment/${id}/show`,
            mask : () => `/appointment/(:id)/show`,
        },
        consiliumResult : {
            path : (id) => `/appointment/${id}/consiliumResult`,
            mask : () => `/appointment/(:id)/consiliumResult`,
        },
    },

    dashboard: {
        path : () => `/dashboard`,
        mask : () => `/dashboard`,
    },

    careteam: {
        list: {
            path: () => `/careteam/list`,
            mask: () => `/careteam/list`,
        },
        create : {
            path : () => `/careteam/create`,
            mask : () => `/careteam/create`,
        },
    },
};

export { Paths };
