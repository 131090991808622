import moment from "moment";
import { getFormat } from "Locale/datetime";
import { Days } from "./Days";
import { observable, reaction } from "mobx";
import _capitalize from "lodash/capitalize";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";

/**
 * формат даты
 */
const DATE_FORMAT = "YYYY-MM-DD";

/**
 * сегодняшняя дата
 */
const TODAY_DATE = moment().format(getFormat("DT000006"));

/**
 * календарь рабочего стола
 */
export class Calendar {

    /**
     * дни на календаре за выбранный период
     */
    @observable days = [];

    /**
     * дни недели (пн, вт, и т.д.)
     */
    weekDays = moment.weekdaysShort(true).map(_capitalize);

    /**
     * сегодняшняя дата в календаре
     */
    todayDate = TODAY_DATE; // YYYY-MM-DD

    /**
     * дата, относительно которой будем двигать календарь
     */
    @observable cursorDate = TODAY_DATE; // YYYY-MM-DD

    /**
     * выбранная дата на календаре
     */
    @observable selectedDate = TODAY_DATE; // YYYY-MM-DD

    /**
     * при инициализации объекта - устанавливаем дни на календаре
     * и регистрируем реакцию на изменение выбранного периода
     */
    constructor() {
        this.setDays();
        moment.locale(HeaderStore.locale)
        this.weekDays = moment.weekdaysShort(true).map(_capitalize);
        reaction(() => this.cursorDate, () => this.setDays());
    }

    /**
     * установка дней на календаре за выбранный период
     */
    setDays = () => {
        this.days = new Days(this.firstDate, this.lastDate, this.todayDate, this.selectedDate, this.setSelectedDate);
    }

    /**
     * 1. установка выбранной даты
     * 2. сбрасываем флаг у текущего выбранного дня
     * 3. устанавливаем флаг у нового дня в календарe
     */
    setSelectedDate = (newDate) => {
        if(this.selectedDate == newDate) {
            return false; // дата не изменилась
        }
        const currentSelectedDayIndex = this.days.findIndex(day => day.date == this.selectedDate);
        if(currentSelectedDayIndex >= 0) {
            this.days[currentSelectedDayIndex].isSelected = false;
        }
        const newSelectedDayIndex = this.days.findIndex(day => day.date == newDate);
        if(newSelectedDayIndex >= 0) {
            this.days[newSelectedDayIndex].isSelected = true;
        }
        this.selectedDate = newDate;
    }

    /**
     * изменить месяц на предыдущий
     */
    prevMonth = () => {
        this.cursorDate = moment(this.cursorDate).subtract(1, "month").format(getFormat("DT000006"));
    }

    /**
     * изменить месяц на следующий
     */
    nextMonth = () => {
        this.cursorDate = moment(this.cursorDate).add(1, "month").format(getFormat("DT000006"));
    }

    /**
     * выбранная дата в удобочитаемом формате
     */
    get currentDate() {
        return moment(this.selectedDate).format(getFormat("DT000002"));
    }

    /**
     * первая дата на календаре
     */
    get firstDate() {
        return moment(this.cursorDate).startOf("month").startOf("week").format(getFormat("DT000006"));
    }

    /**
     * последняя дата на календаре
     */
    get lastDate() {
        return moment(this.cursorDate).endOf("month").endOf("week").format(getFormat("DT000006"));
    }

    /**
     * год
     */
    get year() {
        return moment(this.cursorDate).year();
    }

    /**
     * название месяца
     */
    get month() {
        return _capitalize(moment(this.cursorDate).format("MMMM"));
    }

}
