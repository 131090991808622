import moment from "moment";
import { getFormat } from "Locale/datetime";
import _get from "lodash/get";
import { HumanName } from "MODEL_STORE/FHIR/Elements/HumanName";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";
import {Paths} from "../../../../Routs/Paths";
import { Address } from 'MODEL_STORE/FHIR/Elements/Address';
import {MANUAL_EXTENSION} from "MODEL_STORE/DataSource/Constants/participant_urls";
import {SECONDARY_PARTICIPANT_TYPE_SYSTEM, PRIMARY_PARTICIPANT_TYPE_SYSTEM} from "MODEL_STORE/DataSource/Constants/participant_types";
import { ParticipantType } from "MODEL_STORE/FHIR/Elements/ParticipantType";

const PARTICIPANT_TYPES = {
    SPRF: "experts",
    PPRF: "chairman",
    ATND: "secretary",
    CON: "consultants"
}

const CONSILIUM_LEVEL_SYSTEM = 'http://miramedix.ru/fhir/cs/fcsp-cs-appointment-conciliumLevelCodes';

/**
 * информация о консилиуме
 */
export class Item {

    /**
     * идентификатор консилиума
     */
    id = null;

    /**
     * ссылка на консилиум
     */
    link = null;

    /**
     * название консилиума
     */
    name = null;

    /**
     * дата проведения
     */
    date = null;

    /**
     * время начала проведения
     */
    startTime = null;

    /**
     * время завершения проведения
     */
    endTime = null;

    /**
     * Уровень консилиума
     */
    level = null;

    /**
     * комментарий к консилиуму
     */
    comment = null;

    /**
     * эксперты консилиума (SPRF)
     */
    experts = [];

    /**
     * председатель консилиума (PPRF)
     */
    chairman = null;

    /**
     * секретарь консилиума (ATND)
     */
    secretary = null;

    /**
     * консультанты консилима (CON)
     */
    consultants = [];

    /**
     * организация (место проведения)
     */
    organization = {
        name: null,
        phones: [],
        addresses: []
    }

    /**
     * заболевания, рассматриваемые на консилиуме
     */
    conditions = [];

    /**
     * планы лечения с пунктами обследования
     */
    carePlans = [];

    /**
     * счетчики слотов
     */
    slotCounters = {
        own: 0,
        free: 0,
        busy: 0,
        over: 0
    }

    /**
     * является ли текущий пользователь участником консилиума?
     */
    isParticipant = false;

    /**
     * на входе - ресурс консилиума
     */
    constructor(appointment) {
        this.id = appointment.id;
        this.name = _get(appointment, "description", "n/a");
        this.link = Paths.appointment.edit.path(appointment.id);
        this.date = moment(appointment.start).format(getFormat("DT000002"));
        this.startTime = moment(appointment.start).format(getFormat("DT000018"));
        this.endTime = moment(appointment.end).format(getFormat("DT000018"));
        this.comment = _get(appointment, "comment", "");

        //Вычисляем уровень консилиума
        const codeableConceptLevel = _get(appointment, "serviceCategory", []).find(i => i.coding[0].system === CONSILIUM_LEVEL_SYSTEM);
        this.level = _get(codeableConceptLevel, "coding.0.display");
    }

    /**
     * установка места проведения
     */
    setLocation = (location = null) => {
        // пока из места проведения извлекать нечего
    }

    /**
     * установка организации места проведения
     * нужно извлечь название, адреса и телефоны
     */
    setOrganization = (organization = null) => {
        this.organization.name = _get(organization, "name", "n/a");
        this.organization.phones = _get(organization, "telecom", []).filter(contactPoint => {
            return _get(contactPoint, "system") == "phone"
                && _get(contactPoint, "value", "").length;
        }).map(contactPoint => contactPoint.value);
        this.organization.addresses = _get(organization, "address", []).map(addressElement => {
            return (new Address(addressElement)).fullAddressToString;
        });
    }

    /**
     * установка заболеваний, рассматриваемых на консилиуме
     */
    setConditions = (conditions = []) => {
        this.conditions = conditions.map(condition => ({
            id: condition.id,
            code: _get(condition, "code.coding.0.code", ""),
            name: _get(condition, "code.coding.0.display", ""),
        }));
    }

    /**
     * установка планов лечения
     */
    setCarePlans = (carePlans = []) => {
        this.carePlans = carePlans.map(carePlan => {
            return {
                id: carePlan.id,
                activities: _get(carePlan, "activity", []).map(activity => {
                    return {
                        name: _get(activity, "detail.code.text", ""),
                        desc: _get(activity, "detail.description", ""),
                    }
                }),
                conditions: _get(carePlan, "addresses", []) // ссылки на заболевания, рассматриваемые на консилиуме
            }
        });
    }

    /**
     * установка участника консилиума
     */
    setParticipant = (participant, role, practitioner) => {
        const type = _get(participant, "type.0.coding.0.code");
        if( ! Object.keys(PARTICIPANT_TYPES).includes(type) ) {
            return console.warn(`Незагрегистрированный тип участника консилиума: ${type}`);
        }
        let positions = '';
        let specializations = '';
        let name = '';
        let typeText = '';

        _get(participant, "type.0.coding").forEach(c => {
            if (c.system === SECONDARY_PARTICIPANT_TYPE_SYSTEM || c.system === PRIMARY_PARTICIPANT_TYPE_SYSTEM){
                const participantType = new ParticipantType(_get(participant, "type", ""))
                typeText = participantType.displayCode
            }
        });

        if (role) {
            positions = _get(role, "code", []).map(code => _get(code, "coding.0.display", "")).filter(code => code).join("/");
            specializations = _get(role, "specialty", []).map(specialty => _get(specialty, "coding.0.display", "")).filter(code => code).join("/");
        }
        if (practitioner) {
            name = Array.isArray(practitioner.name) && practitioner.name.length ? HumanName.fullNameShort(practitioner.name[0]) : "Имя не указано";
        }

        positions = positions.length ? positions : this.getPositionManual(_get(participant, 'actor'));
        name = name.length ? name : this.getPractitionerManual(_get(participant, 'actor'));
        specializations = specializations.length ? specializations : this.getSpecializationManual(_get(participant, 'actor'));

        const actor = {
            name: name,
            position: (positions + (specializations ? ` | ${specializations}` : "")),
            typeText: typeText
        }
        const property = PARTICIPANT_TYPES[type];
        if( Array.isArray(this[property]) ) {
            this[property].push(actor);
        } else {
            this[property] = actor;
        }
        if (this.isParticipant === false) {
            this.isParticipant = _get(practitioner, 'id') === _get(AuthStore, "currentUserData.active_role_id");
        }
    }

    /**
     * Получение сотрудника при ручном заполнении участника
     */
    getPractitionerManual(actor) {
        let extensionList = _get(actor, "extension", []);
        const extensionPrc = extensionList.find(e => e.url === MANUAL_EXTENSION.PRACTITIONER_URL);
        return _get(extensionPrc, "valueReference.display", '');
    }

    /**
     * Получение Сотрудника, либо объект системы, либо текст из ручного режима
     */
    getPositionManual(actor) {
        let extensionList = _get(actor, "extension", []);
        const extensionPos = extensionList.find(e => e.url === MANUAL_EXTENSION.POSITION_URL);
        const text = _get(extensionPos, "valueCodeableConcept.text", "");
        if (text.length) {
            return text;
        } else {
            return _get(extensionPos, "valueCodeableConcept.coding.0.display", '');
        }
    }

    /**
     * Получить текстовое значечние специализации заполненное вручную
     */
    getSpecializationManual(actor) {
        let extensionList = _get(actor, "extension", []);
        const extensionSpec = extensionList.find(e => e.url === MANUAL_EXTENSION.SPECIALIZATION_URL);
        const text = _get(extensionSpec, "valueCodeableConcept.text", "");
        if (text.length) {
            return text;
        } else {
            return _get(extensionSpec, "valueCodeableConcept.coding.0.display", '');
        }
    }

    /**
     * установка слотов и заявок (вычисление кол-ва слотов разных типов)
     */
    setSlotsAndServiceRequests = (slots = [], serviceRequests = []) => {
        const currentPractitionerId = _get(AuthStore, "currentUserData.active_role_id");
        this.slotCounters.own = serviceRequests.filter(serviceRequest => {
            return _get(serviceRequest, "requester.id") == currentPractitionerId && _get(serviceRequest, "status") !== "revoked";
        }).length;
        this.slotCounters.free = slots.filter(slot => slot.status == "free").length;
        this.slotCounters.busy = slots.filter(slot => slot.status == "busy").length;
        this.slotCounters.over = slots.filter(slot => slot.overbooked).length;
    }

    /**
     * участники консилиума
     */
    get participants() {
        return {
            experts: this.experts,
            chairman: this.chairman,
            secretary: this.secretary,
            consultants: this.consultants
        }
    }
}
