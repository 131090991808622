import React from "react";
import {Paths} from "../Routs/Paths";
import Consilium from "../../GUI_MAIN/icons/Consilium";
import Patient from "src/GUI_MAIN/icons/Patient";
import RequestIcon from "../../GUI_MAIN/icons/RequestIcon";
import Home from "../../GUI_MAIN/icons/Home";
import Protocol from "../../GUI_MAIN/icons/Protocol";
import ParticipantIcon from "../../GUI_MAIN/icons/ParticipantIcon";

const MenuItemPatientList =  {
    id: 2,
    linkTo: Paths.patient.list.path(),
    linkText: 'SS001002',
    linkImg: (color) => <Patient stroke={color} />,
};

const MenuItemAppointment = {
    id: 3,
    linkTo: Paths.appointment.list.path(),
    linkText: 'SS001004',
    linkImg: (color) => <Consilium stroke={color} />,
    subMenu: [
        {     linkTo: Paths.appointment.list.path() + '#f_future',
            linkText: 'Предстоящие',
        },
        {
            linkTo: Paths.appointment.list.path()+'#f_archive',
            linkText: 'Архив',
        }
    ],
};

const MenuItemCareTeams = {
    id: 20,
    linkTo: Paths.careteam.list.path(),
    linkText: 'SS001005',
    linkImg: (color) => <ParticipantIcon fill={color} />,
};

const MenuItemServiceRequest = {
    id: 4,
    linkTo: Paths.servicerequest.list.path(),
    linkText: 'SS001006',
    linkImg: (color) => <RequestIcon fill={color}/>,
    subMenu: [
        {
            linkTo: Paths.servicerequest.list.path() + '#f_not_close',
            linkText: 'В обработке',
        },
        {
            linkTo: Paths.servicerequest.list.path() + '#f_on_hold',
            linkText: 'На рассмотрении',
        },
        {
            linkTo: Paths.servicerequest.list.path() + '#f_back',
            linkText: 'Отклоненные',
        },
        {
            linkTo: Paths.servicerequest.list.path() + '#f_archive',
            linkText: 'Архив заявок',
        }
    ],
};

const MenuItemDashboard = {
    id: 1,
    linkTo: Paths.dashboard.path(),
    linkText: 'SS001003',
    linkImg: (color) => <Home stroke={color}/>,
};

const MenuItemProtocols = {
    id: 8,
    linkTo: Paths.protocol.list.path(),
    linkText: 'SS001007',
    linkImg: (color) => <Protocol stroke={color}/>,
    subMenu: [
        {
            linkTo: Paths.protocol.list.path() + '#f_in_work',
            linkText: 'В работе',
        },
        {
            linkTo: Paths.protocol.list.path() + '#f_to_sign',
            linkText: 'К подписанию',
        },
        {
            linkTo: Paths.protocol.list.path() + '#f_ready',
            linkText: 'Готов',
        },
        {
            linkTo: Paths.protocol.list.path() + '#f_archive',
            linkText: 'Архив',
        }
    ],
};

export {MenuItemAppointment, MenuItemPatientList, MenuItemServiceRequest, MenuItemDashboard, MenuItemProtocols, MenuItemCareTeams};
