import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

const WeekDaysWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const WeekDay = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: ${ props => props.theme.OpenSansBold };
  font-size: ${ props => props.theme.normalFontSize };
`;

@observer
export class WeekDays extends React.PureComponent {
    render () {
        return (
            <WeekDaysWrapper>
                { this.props.calendar.weekDays.map((day, key) => <WeekDay key={key}>{day}</WeekDay>)}
            </WeekDaysWrapper>
        )
    }
}
