import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import Organization from "GUI_MAIN/icons/Organization";
import { EOLocale } from "eo-locale";

const LocationWrapper = styled.div`
  margin-top: 8px;
  padding-left: 16px;
  font-family: ${props => props.theme.OpenSansRegular};
  color: ${props => props.theme.darkMainTextColor};
  font-size: ${props => props.theme.mediumFontSize};
  margin-bottom: 13px;
`;

const PhoneNumber = styled.div`
`;

@observer
export class AppointmentLocation extends React.PureComponent {
    render() {
        const {
            phones,
            addresses,
        } = this.props.organization;
        return (
            <LocationWrapper>
                { this.props.show && "Адрес: " + addresses.join(" / ") }
                { this.props.show && !! phones.length && <PhoneNumber><EOLocale.Text id={'SS902509'} /> <span>{phones[0]}</span></PhoneNumber> }
            </LocationWrapper>
        )
    }
}
