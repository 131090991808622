import _get from "lodash/get";
import moment from "moment";
import { getFormat } from "Locale/datetime";
import { Patient } from "MODEL_STORE/FHIR/Resources/Patient";
import { Appointment } from "MODEL_STORE/FHIR/Resources/Appointment";
import {Paths} from "../../../../Routs/Paths";
import {CustomServiceRequest} from "../../../../CustomModel/FHIR/CustomServiceRequest/CustomServiceRequest";

/**
 * информация о заявке
 */
export class Item {

    /**
     * идентификатор заявки
     */
    id = null;

    /**
     * ссылка на заявку
     */
    link = null;

    /**
     * статус заявки
     */
    status = null;

    /**
     * возраст пациента
     */
    patientAge = null;

    /**
     * пол пациента
     */
    patientSex = null;

    /**
     * имя пациента
     */
    patientName = null;

    /**
     * id создавшего
     */
    requester = null;

    /**
     * заболевания-основания для заявки
     */
    conditions = [
        /**
         * {
         *   id,
         *   code,
         *   name,
         * }
         */
    ];

    /**
     * кол-во дней до начала консилиума
     */
    appointmentDays = null;

    /**
     * дата проведения консилиума
     */
    appointmentDate = null;

    /**
     * название МО консилиума
     */
    organizationName = null;

    /**
     * время рассмотрения заявки
     */
    slotStartTime = null;

    /**
     * Причина возвращения на доработку
     */
    withdrawComment = "";

    /**
     * на входе - необходимые ресурсы для отображения заявки на рабочем столе
     */
    constructor({appointment, slot, organization, serviceRequest, patient, conditions = [], compositions = []}) {
        patient = new Patient(patient);
        appointment = new Appointment(appointment);
        serviceRequest = new CustomServiceRequest(serviceRequest);
        this.id = serviceRequest.id;
        this.withdrawComment = serviceRequest.withdrawComment;
        this.link = Paths.servicerequest.edit.path(serviceRequest.id);
        this.status = serviceRequest.checkStatusrequest(compositions, () => "error");
        this.patientAge = patient.ageInYears;
        this.patientSex = patient.genderPatientName;
        this.patientName = patient.displayFullName;
        this.conditions = conditions.map(condition => ({
            id: condition.id,
            code: _get(condition, "code.coding.0.code", ""),
            name: _get(condition, "code.coding.0.display", ""),
        }));
        this.appointmentDays = appointment.date.daysWithLocale;
        this.appointmentHours = appointment.date.hoursWithLocale;
        this.appointmentDate = appointment.date.human;
        this.appointmentTime = appointment.date.startTimeHuman;
        this.appointmentLive = moment().isAfter(appointment.start) && moment().isBefore(appointment.end);
        this.organizationName = organization.name;
        this.slotStartTime = (slot && slot.start) ? moment(slot.start).format(getFormat("DT000018")) : null;
        this.requester = serviceRequest.requester.id
    }

}
