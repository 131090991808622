import React from "react";
import styled from "styled-components";
import {EOLocale} from "eo-locale";

const NoAppointmentContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const NoItemText = styled.div`
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.bigFontSize};
  color: ${props => props.theme.mediumTextColor};
`;

export class AppointmentsEmpty extends React.PureComponent {
    render() {
        return (
          <NoAppointmentContent>
              <NoItemText><EOLocale.Text id={"SS001028"}/></NoItemText>
          </NoAppointmentContent>
        );
    }
}
