import React from "react";
import Table from "GUI_MAIN/Elements/Table";
import Status from "GUI_MAIN/Elements/Status";
import { observer } from "mobx-react";
import styled from "styled-components";
import MKBCode from "GUI_MAIN/Elements/MKBCode";
import {EOLocale} from "eo-locale";
import { translate } from "Locale/translate";
import {tooltipChecker} from "GUI_MAIN/Utils/tooltipChecker";
import ReactTooltip from "react-tooltip";
import Logger from "../../../../logger";

const StatusStart = styled.div`
  ${props => props.minWidth && 'min-width:' + props.minWidth};
`;

const OrganizationName = styled.div`
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${props => props.theme.OpenSansRegular};
`;

const NamePatient = styled.div`
  color: #232735;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Row = styled.div`
  display: flex;
  margin-top: 3px;
  flex-wrap: wrap;
  align-items: center;
`;

const Info = styled.span`
  margin-right: 16px;
  color: #8B90A0;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

/**
 * шаблон для таблицы с заявками
 */
const TABLE_TEMPLATE = [{
        flex: '1 0 240px',
        justify: 'flex-start',
        title: <EOLocale.Text id={"SS001015"}/>,
        field: 'patient'
    }, {
        flex: '0 0 136px',
        justify: 'flex-start',
        title: <EOLocale.Text id={"SS001016"}/>,
        field: 'date'
    }, {
        flex: '0 0 96px',
        justify: 'flex-start',
        title: <EOLocale.Text id={"SS021001"}/>,
        field: 'appointmentTime'
    }, {
        flex: '0 0 80px',
        justify: 'flex-start',
        title: <EOLocale.Text id={"SS021002"}/>,
        field: 'slotStartTime'
    },{
        flex: '1 1 216px',
        justify: 'flex-start',
        title: <EOLocale.Text id={"SS001017"}/>,
        field: 'organization'
    }, {
        flex: '0 0 232px',
        justify: 'flex-start',
        title: <EOLocale.Text id={"SS001018"}/>,
        field: 'status',
    }, {
        flex: '0 0 120px',
        justify: 'flex-start',
        title: "Статус консилиума",
        field: 'appointmentStatus'
    }
];

@observer
export class ServiceRequestsTable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();

        this.state = {
            tableScrolledHeight: 0
        }
    }

    componentWillUnmount() {
        ReactTooltip.hide();
    }

    /**
     * данные для таблицы с заявками
     */
    get tableData() {
        return this.props.items.map(this.getTableRow);
    }

    /**
     * данные для строки с заявкой
     */
    getTableRow = (item) => {
        return {
            rowTemplate: {
                date: this.getDateCell(item),
                slotStartTime: this.getSlotStartTimeCell(item),
                appointmentTime: this.getAppointmentTimeCell(item),
                status: this.getStatusCell(item),
                patient: <PatientCell item={item}/>,
                organization: <OrganizationNameCell item={item}/>,
                appointmentStatus: this.getAppointmentStatus(item),
                click: () => this.props.redirect(item.link),
            }
        }
    };

    /**
     * ячейка с датой консилиума
     */
    getDateCell = (item) => {
        return item.appointmentDate;
    };

    /**
     * ячейка с временем проведения консилиума
     */
    getAppointmentTimeCell = (item) => {
        return item.appointmentTime;
    };

    /**
     * ячейка с временем рассмотрения заявки
     */
    getSlotStartTimeCell = (item) => {
        return item.slotStartTime;
    }

    /**
     * ячейка со статусом заявки
     */
    getStatusCell = (item) => {
        return (
            <Status status={item.status.value}
                    mainWidth={'216px'}
                    statusText={<EOLocale.Text id={item.status.name}/>}
                    dataTip={item.withdrawComment}
                    notNeedMarginLeft/>
        )
    };

    getAppointmentStatus = (item) => {
        let id = item.appointmentDays;
        let days = item.appointmentDays;
        if (id !== "SS001021") {
            let modulo = days % 10;
            if(days >= 11 && days <= 14) {
                id = "PP001001";
            }
            else if(modulo === 1) {
                id = "PP001002";
            }
            else if([2, 3, 4].includes(modulo)) {
                id = "PP001003";
            }
            else id = "PP001001";

            if(modulo === 0 ) {
                let hours = item.appointmentHours;
                if(hours > 0) {
                    hours++;
                    id = "FCSPOK_158_1";
                }
                else {
                    id = "FCSPOK_158_2";
                }
                return (
                  <StatusStart>
                      <EOLocale.Text id={id} h={hours}/>
                  </StatusStart>
                )
            }
        }

        if ( item.appointmentLive ) {
            id = "SS00100";
        }

        return (
          <StatusStart>
              <EOLocale.Text id={id} days={days}/>
          </StatusStart>
        )
    }

    componentDidMount() {
        //высчитывает высоту области для сколла у таблицы с заявками
        this.setState({tableScrolledHeight: this.tableRef.current.clientHeight - 50})
    }

    render() {

        setTimeout(tooltipChecker, 0);

        return (
            <TableWrapper ref={this.tableRef}>
                <Table
                    headerBG={'white'}
                    headerBorderBottom
                    data={this.tableData}
                    tableTemplate={TABLE_TEMPLATE}
                    heightTableContent={this.state.tableScrolledHeight + 'px'}
                    headerPadding={'4px 16px'}
                    cellHeaderPadding={'0px 8px'}
                    cellPadding={'0px 8px'}
                    rowPadding={"12px 16px"}
                    autoHeight
                />
            </TableWrapper>
        )
    }
}

class PatientCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.name = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        tooltipChecker("text", [this.name]);
    }

    componentDidMount() {
        tooltipChecker("text", [this.name]);
    }

    render() {

        const {item} = this.props;

        return(
          <InfoWrapper>
              <NamePatient ref={this.name}>
                  { item.patientName }
              </NamePatient>
              <Row>
                  { item.conditions.map(condition => {
                      return (
                        <MKBCode
                          key={condition.id}
                          code={condition.code}
                          margin={'0 5px 5px 0'}
                          dataTip={condition.name}
                          dataFor={`condition-${condition.id}`}
                          cursorPointer
                        />
                      )
                  }) }
                  <Info>
                      { item.patientAge }, { translate(item.patientSex.props.id) === "Мужской" ? "мужчина" : "женщина"}
                  </Info>
              </Row>
          </InfoWrapper>
        )
    }
}

class OrganizationNameCell extends React.PureComponent {

    constructor(props) {
        super(props);

        this.name = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        tooltipChecker("text", [this.name]);
    }

    componentDidMount() {
        tooltipChecker("text", [this.name]);
    }

    render() {
        return (
          <OrganizationName ref={this.name}>
              {this.props.item.organizationName}
          </OrganizationName>
        )
    }
}
