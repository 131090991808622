import _get from "lodash/get";
import { observable } from "mobx";
import { ConditionMkb10OncologyCodesValueSet } from "MODEL_STORE/DataSource/Stores/ValueSets/ConditionMkb10OncologyCodesValueSet";

/**
 * задержка перед запросом
 */
const SEARCH_REQUEST_TIMEOUT = 500;

export class Mkb10Filter {

    /**
     * таймаут перед запросом
     */
    @observable timeout = null;

    /**
     * справочник кодов МКБ-10 (соответствующих онкологическим заболеваниям)
     */
    @observable valueset = new ConditionMkb10OncologyCodesValueSet();

    /**
     * код выбранного концепта
     */
    @observable value = "";

    /**
     * сброс фильтра
     */
    reset = () => this.value = "";

    /**
     * загрузка концептов
     * если поисковая строка указана, то ищем по ней
     * иначе грузим только родительские концепты
     */
    loadConcepts = (filter) => {
        this.valueset.search(filter);
    }

    /**
     * выбранное значение кода МКБ-10 пациента для React-Select
     */
    get option() {
        return this.valueset.options.find(option => option.value == this.value) || "";
    }

    /**
     * возможные значения кода МКБ-10 пациента для React-Select
     */
    get options() {
        return this.valueset.options;
    }

    /**
     * установка кода МКБ-10 пациента
     */
    setOption = (selected) => {
        this.value = _get(selected, "value", "");
    }

    /**
     * поиск кодов МКБ-10 по названию
     */
    search = (filter) => {
        if( ! filter || filter.length < 2) {
            return filter;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.loadConcepts(filter), SEARCH_REQUEST_TIMEOUT);
    }

}
