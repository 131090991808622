import React from "react";
import { observer } from "mobx-react";
import { WeekDays } from "./WeekDays";
import styled from "styled-components";
import { MonthDays } from "./MonthDays";
import { Navigation } from "./Navigation";

const ContentWrapper = styled.div`
  font-size: ${props => props.theme.mediumFontSize};
  width: 345px;
  border-right: 1px solid ${props => props.theme.borderNavigation};
  padding: 8px 8px 16px;
`;

const DateWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    border-bottom: 1px solid ${props => props.theme.borderNavigation};
`;

const CalendarWrapperInner = styled.div`
  width: 100%;
`;

const CalendarСontainer = styled.div`
  display: flex;
  flex-direction: column;`;

@observer
export class Calendar extends React.PureComponent {
    render () {
        return (
            <ContentWrapper>
                <DateWrapper>
                    <Navigation calendar={this.props.calendar}/>
                </DateWrapper>
                <CalendarWrapperInner>
                    <CalendarСontainer>
                        <WeekDays calendar={this.props.calendar}/>
                        <MonthDays calendar={this.props.calendar}/>
                    </CalendarСontainer>
                </CalendarWrapperInner>
            </ContentWrapper>
        )
    }
}
