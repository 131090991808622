import { reaction } from "mobx";
import { UrlSearchParams } from "GUI_MAIN/Utils/UrlSearchParams";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";

/**
 * счетчики для календаря
 */
export class Counters {

    /**
     * объект календаря на рабочем столе
     */
    calendar = null;

    /**
     * на входе - объект календаря на рабочем столе
     */
    constructor(calendar) {
        this.calendar = calendar;
        reaction(() => this.calendar.cursorDate, this.loadData, { fireImmediately: true } );
    }

    /**
     * параметры запроса
     */
    get queryString() {
        const params = {
            "date-end": this.calendar.lastDate,
            "date-start": this.calendar.firstDate,
            "auth-practitioner-role-id": AuthStore.currentUserData.active_role_id
        }
        // if ( ! AuthStore.currentUserData.isRoot() ) {
        //     params["organization-id"] = AuthStore.currentUserData.active_organization_id;
        // }
        return UrlSearchParams(params);
    }

    /**
     * полная ссылка
     */
    get endpoint() {
        return `/api/tb/appointments/dashboard-calendar-counters?${this.queryString}`;
    }

    /**
     * загрузка данных
     */
    loadData = () => {
        return basicRequestGet(this.endpoint).then(this.onLoad, this.onError);
    }

    /**
     * при успешной загрузке данных
     */
    onLoad = ({data: response}) => {
        if ( ! Array.isArray(response.data) ) {
            throw "При запросе к AidboxQuery 'dashboard-calendar-counters' произошла ошибка";
        }
        this.calendar.days.forEach(calendarDay => {
            const info = response.data.find(({date}) => date == calendarDay.date);
            if ( ! info ) return false;
            calendarDay["hasAppointments"] = !! info.appointments;
            calendarDay["hasServiceRequests"] = !! info.servicerequests;
            calendarDay["hasOwnAppointments"] = !! info.own_appointments;
            calendarDay["hasOwnServiceRequests"] = !! info.own_servicerequests;
        });
    }

    /**
     * при ошибке загрузки данных
     */
    onError = (error) => {
        console.error("При загрузке информации о счетчиках произошла ошибка", error);
    }

}
