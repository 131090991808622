import React from "react";
import Place from "GUI_MAIN/icons/Place";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import { Counter } from "GUI_MAIN/Elements/Slot/Counter";
import PlaceOutlinedIcon from "GUI_MAIN/icons/PlaceOutlinedIcon";
import { Translator } from "eo-locale";
import {locales} from "Locale";

const PlacesPanel = styled.div`
  display: flex;
  padding: 0 20px;
`;

const PlaceWrapper = styled.div`
  margin-right: ${props => props.marginRight ? props.marginRight : '20px'};
  cursor: pointer;
  position: relative;`;

const CounterWrapper = styled.div`
  position: absolute;
  right: ${props => props.right ? props.right : '-16px'};
  top: -2px;
  z-index: 4;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


@inject('HeaderStore')
@observer
export class AppointmentSlotCounters extends React.PureComponent {

    render() {
        const {counters} = this.props;
        const {
            own,
            free,
            busy,
            over
        } = counters;

        const translator = new Translator(this.props.HeaderStore.locale, locales);

        return (
            <PlacesPanel>
                { busy > 0 &&
                    <PlaceWrapper data-tip={translator.getMessageById('SS021004')}>
                        <CounterWrapper>
                            <Counter value={busy}/>
                        </CounterWrapper>
                        <Place fill={'#A1A4B1'}/>
                    </PlaceWrapper>
                }
                { own > 0 &&
                    <PlaceWrapper data-tip={translator.getMessageById('SS021005')}>
                        <CounterWrapper>
                            <Counter value={own}/>
                        </CounterWrapper>
                        <Place fill={'#27AE60'} stroke={'#27AE60'}/>
                    </PlaceWrapper>
                }
                { free > 0 &&
                    <PlaceWrapper data-tip={translator.getMessageById('SS021006')}>
                        <CounterWrapper right={'-15px'}>
                            <Counter value={free}/>
                        </CounterWrapper>
                        <PlaceOutlinedIcon fill={'#A1A4B1'}/>
                    </PlaceWrapper>
                }
                { over > 0 &&
                    <PlaceWrapper data-tip={translator.getMessageById('SS902510')}>
                        <CounterWrapper>
                            <Counter value={over}/>
                        </CounterWrapper>
                        <Place fill={'#cc2418'} stroke={'#cc2418'}/>
                    </PlaceWrapper>
                }
            </PlacesPanel>
        )
    }
}
