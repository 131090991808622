import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import IconButton from "GUI_MAIN/Elements/IconButton";

const NavigationContainer=styled.div`
    display: inline-block;
    width: 100%;
`;

const ContainerInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;`;

const DateWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const Date = styled.div`
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansSemiBold};
    color: ${props=>props.theme.darkMainTextColor};
`;

const LeftArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none">
        <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" fill="#A1A4B1"/>
    </svg>
)

const RightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none">
        <path d="M2 0L0.589996 1.41L5.17 6L0.589996 10.59L2 12L8 6L2 0Z" fill="#A1A4B1"/>
    </svg>
)

@observer
export class Navigation extends React.PureComponent {
    render() {
        const {
            year,
            month,
            prevMonth,
            nextMonth            
        } = this.props.calendar;
        return (
            <NavigationContainer>
                <ContainerInner>
                    <IconButton
                        widthButton={"20px"}
                        heightButton={"20px"}
                        icon={<LeftArrow/>}
                        onClick={prevMonth}
                    />
                    <DateWrapper>
                        <Date> {month}</Date>
                        <Date>{year}</Date>
                    </DateWrapper>
                    <IconButton
                      widthButton={"20px"}
                      heightButton={"20px"}
                      icon={<RightArrow/>}
                      onClick={nextMonth}
                    />
                </ContainerInner>
            </NavigationContainer>
        );
    }
}
