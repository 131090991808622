import {observable, reaction} from "mobx";
import {Translator} from "eo-locale";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {locales} from "Locale";
import {PagePaginationStore} from "MODEL_STORE/DataSource/Stores/PagePaginationStore";

/**
 * варианты сортировки
 */
const ORDER_OPTIONS = () => {
    const translator = new Translator(HeaderStore.locale, locales);
    return [{
        value: "-.period.start,status",
        label: translator.getMessageById("SS012007")
    }, {
        value: ".period.start,status",
        label: translator.getMessageById("SS012008")
    }]
};

/**
 * варианты статусов
 */
const STATUS_OPTIONS = () => {
    const translator = new Translator(HeaderStore.locale, locales);
    return [{
        value: "active",
        label: translator.getMessageById("SS012003")
    }, {
        value: "inactive",
        label: translator.getMessageById("SS012004")
    }]
};

/**
 * изначальное состояние
 */
const INITIAL_STATE = {
    PAGE: 1,
    NAME: "",
    DATE: "",
    TOTAL: 0,
    COUNT: 15,
    ORDER: ORDER_OPTIONS()[0],
    STATUS: null,
    PRACTITIONER: "" // фио сотрудника
};
export const LOCAL_STORE_FILTER_KEY = "CARETEAM_FILTERS";
export const LOCAL_STORE_FIELDS_KEY = "CARETEAM_FIELDS";

/**
 * фильтры для данных
 */
export class Filters {

    /**
     * ссылка на данные
     */
    data = null;

    /**
     * таймаут для отложенного поиска (по имени например)
     */
    timeout = null;

    /**
     * текущая страница
     */
    @observable page = INITIAL_STATE.PAGE;

    /**
     * название команды
     */
    @observable name = INITIAL_STATE.NAME;

    /**
     * дата
     */
    @observable date = INITIAL_STATE.DATE;

    /**
     * кол-во результатов на странице
     */
    @observable count = INITIAL_STATE.COUNT;

    /**
     * кол-во результатов (всего)
     */
    @observable total = INITIAL_STATE.TOTAL;

    /**
     * тип сортировки
     */
    @observable order = INITIAL_STATE.ORDER;

    /**
     * статус (по умолчанию)
     */
    @observable status = INITIAL_STATE.STATUS;

    /**
     * фио участника
     */
    @observable practitioner = INITIAL_STATE.PRACTITIONER;

    /**
     * организация (по умолчанию)
     */
    @observable organization = INITIAL_STATE.ORGANIZATION;

    @observable loadedFromLocalStore = null;
    loadedFieldFromLocalStore = false;

    // Код раздела сайта, для которого применяется пагинация
    pagePaginationStore = new PagePaginationStore(LOCAL_STORE_FILTER_KEY);

    /**
     * data - ссылка на данные
     */
    constructor(data) {
        this.count = this.pagePaginationStore.getPageCount();
        this.data = data;
        this.managingOrganization = this.data.managingOrganization;
        // Устанавливаем организацию по умолчанию
        reaction(() => this.managingOrganization.organizations, () => {
            if (!this.organization){
                this.setOrganization(this.managingOrganization.value);
            }
            this.setFirstPage();
        });
        // reaction(() => this.loadedFromLocalStore, () => {
        //     if (!this.loadedFromLocalStore && !this.loadedFieldFromLocalStore) {
        //         this.loadFieldFromLocalStore();
        //     }
        // });
        // this.setOrganization(this.managingOrganization.value);
        this.loadFromLocalStore();
    }

    loadFromLocalStore = () => {
        let filters = localStorage.getItem(LOCAL_STORE_FILTER_KEY);
        if (!filters) {
            return null;
        }
        filters = JSON.parse(filters);
        this.loadedFromLocalStore = true;

        this.setName(filters.name);
        this.setDate(filters.date);
        this.setOrganization(filters.organization);
        this.setStatus(filters.status);
        this.setOrder(filters.order);
        this.setPractitioner(filters.practitioner);
    };

    loadFieldFromLocalStore = () => {
        let filters = localStorage.getItem(LOCAL_STORE_FIELDS_KEY);
        if (!filters) {
            this.loadedFieldFromLocalStore = true;
            return null;
        }
        filters = JSON.parse(filters);
        if (filters.name !== undefined)
            this.setName(filters.name);
        if (filters.date !== undefined)
            this.setDate(filters.date);
        if (filters.organization !== undefined)
            this.setOrganization(filters.organization);
        if (filters.status !== undefined)
            this.setStatus(filters.status);
        if (filters.order !== undefined)
            this.setOrder(filters.order);
        if (filters.practitioner !== undefined)
            this.setPractitioner(filters.practitioner);
        this.loadedFieldFromLocalStore = true;
    };

    /**
     * Флаг, что данные загружены с LocalStore и можно дальше записывать изменения в LocalStore
     */
    get isLoadFromLocalStore() {
        return this.loadedFromLocalStore !== null;
    }

    /**
     * кол-во страниц
     */
    get pages() {
        return Math.ceil(this.total / this.count);
    }

    /**
     * тип сортировки
     */
    get orderValue() {
        if ( this.order ) {
            return this.orders.find(option => option.value == this.order.value) || "";
        }
        return "";
    }

    /**
     * статус команды
     */
    get statusValue() {
        if ( this.status ) {
            return this.statuses.find(option => option.value == this.status.value) || "";
        }
        return "";
    }

    /**
     * Организация команды
     */
    get organizationValue() {
        if ( this.organization ) {
            return this.organization;
        }
        return "";
    }

    /**
     * варианты сортировки
     */
    get orders() {
        return ORDER_OPTIONS();
    }

    /**
     * варианты статусов
     */
    get statuses() {
        return STATUS_OPTIONS();
    }

    /**
     * изменение страницы
     */
    setPage = (page = INITIAL_STATE.PAGE) => {
        this.page = page;
        this.data.search();
    }

    /**
     * установка первой страницы
     */
    setFirstPage = () => {
        this.setPage(INITIAL_STATE.PAGE);
    }

    /**
     * Нажатие на кнопку Поиск
     */
    searchData = () => {
        localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify({
            name: this.name,
            date: this.date,
            practitioner: this.practitioner,
            organization: this.organization,
            status: this.status,
            order: this.order,
        }));
        this.setPage(INITIAL_STATE.PAGE);
    }

    /**
     * изменение фио (вызываем функцию обновления после таймаута)
     */
    setName = (name = INITIAL_STATE.NAME) => {
        this.name = name;
        this.setLocalStorage('name', name);
    }

    /**
     * изменение фио участника
     */
    setPractitioner = (value = INITIAL_STATE.PRACTITIONER) => {
        this.practitioner = value;
        this.setLocalStorage('practitioner', value);
    }

    /**
     * изменение даты
     */
    setDate = (date = INITIAL_STATE.DATE) => {
        this.date = date;
        this.setLocalStorage('date', date);
    }

    /**
     * изменение организации
     */
    setOrganization = (organization = INITIAL_STATE.ORGANIZATION) => {
        this.organization = organization;
        this.setLocalStorage('organization', organization);
    }

    /**
     * изменение кол-ва результатов
     */
    setCount = (count = INITIAL_STATE.COUNT) => {
        this.count = count;
        this.pagePaginationStore.setPageCount(this.count);
        this.setFirstPage();
    }

    /**
     * изменение количества результатов (всего)
     */
    setTotal = (total = 0) => {
        this.total = total;
    }

    /**
     * изменение сортировки
     */
    setOrder = (order = INITIAL_STATE.ORDER) => {
        this.order = order;
        this.setLocalStorage('order', order);
    }

    /**
     * изменение сортировки
     */
    changeOrder = () => {
        const index = this.order.value == this.orders[0]["value"] ? 1 : 0;
        return this.setOrder(this.orders[index]);
    }

    /**
     * изменение сортировки и поиск
     */
    changeOrderAndSearch = () => {
        this.changeOrder();
        this.data.search();
    }

    /**
     * изменение статуса
     */
    setStatus = (status = INITIAL_STATE.STATUS) => {
        this.status = status;
        this.setLocalStorage('status', status);
    }

    /**
     * сброс фильтров
     */
    reset = () => {
        Object.keys(INITIAL_STATE).forEach(key => {
            this[key.toLowerCase()] = INITIAL_STATE[key];
        });
        this.deleteLocalStoreData();
        this.data.search();
    };

    deleteLocalStoreData = () => {
        localStorage.removeItem(LOCAL_STORE_FILTER_KEY);
        localStorage.removeItem(LOCAL_STORE_FIELDS_KEY);
        this.pagePaginationStore.clearPageCount();
    };

    setLocalStorage = (paramKey, value) => {
        if (this.loadedFieldFromLocalStore) {
            let filters = localStorage.getItem(LOCAL_STORE_FIELDS_KEY);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[paramKey] = value;
            localStorage.setItem(LOCAL_STORE_FIELDS_KEY, JSON.stringify(filters));
        }
    }
}
