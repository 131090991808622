import { reaction } from "mobx";
import { Calendar } from "./Calendar/index";

import { Counters } from "./AidboxQuery/Counters"; // import { Counters } from "./Counters/index";

import { Appointments } from "./AidboxQuery/Appointments"; // import { Appointments } from "./Appointments/index";

import { ServiceRequests } from "./AidboxQuery/ServiceRequests"; // import { ServiceRequests } from "./ServiceRequests/index";

/**
 * данные для рабочего стола
 */
export class Data {

    /**
     * календарь рабочего стола
     */
    calendar = new Calendar();

    /**
     * счетчики консилиумов и заявок (в том числе собственных)
     */
    counters = new Counters(this.calendar);

    /**
     * информация о консилиумах на текущую дату
     */
    appointments = new Appointments(this.calendar.selectedDate);

    /**
     * информация о заявках на текущую дату
     */
    serviceRequests = new ServiceRequests(this.calendar.selectedDate);

    /**
     * регистрируем реакции на изменение дат
     */
    constructor() {
        reaction(() => this.calendar.selectedDate, (selectedDate) => {
            this.appointments.load(selectedDate);
            this.serviceRequests.load(selectedDate);
        });
    }

}
