import moment from "moment";
import { observable } from "mobx";
import { Items } from "../Appointments/Items";
import { Response } from "../Appointments/Request/Response";
import { UrlSearchParams } from "GUI_MAIN/Utils/UrlSearchParams";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";

/**
 * параметры запроса консилиумов
 */
const APPOINTMENT_SEARCH_PARAMS = {
    "_include": [
        "Appointment:slot:Slot", // слоты
        "Appointment:location:Location", // место проведения консилиума
        "Appointment:actor:PractitionerRole", // роли участников консилиума
        "Appointment:based-on:ServiceRequest", // заявки на консилиум
        "Appointment:supporting-info:CarePlan", // ссылки на планы лечения
        "Appointment:reason-reference:Condition", // заболевания, рассматриваемые на консилиуме
    ].join(","),
    "_include:iterate": [
        "Location:organization:Organization", // организации
        "PractitionerRole:practitioner:Practitioner", // сотрудники (участники консилиума)
    ].join(","),
    "_elements": [
        "Slot.id", // идентификатор слота
        "Slot.status", // статус слота
        "Slot.overbooked", // слот сверх нормы?
        "CarePlan.id", // идентификатор плана лечения
        "CarePlan.activity", // пункты плана лечения
        "CarePlan.addresses", // ссылки на заболевания
        "Appointment.id", // идентификатор консилиума
        "Appointment.slot", // слоты консилиума
        "Appointment.start", // время вроведения консилиума
        "Appointment.end", // время вроведения консилиума
        "Appointment.basedOn", // ссылки на заявки
        "Appointment.comment", // комментарий к консилиуму
        "Appointment.description", // название консилиума
        "Appointment.participant", // ссылки на участников
        "Appointment.serviceCategory", // уровень консилиума
        "Appointment.reasonReference", // заболевания консилиума
        "Appointment.supportingInformation", // ссылки на планы лечения
        "Location.id", // идентификатор локации
        "Location.managingOrganization", // ссылка на организацию
        "Organization.id", // идентификатор организации
        "Organization.name", // название организации
        "Organization.address", // адреса оргнанизации
        "Organization.telecom", // средства связи с организацией
        "ServiceRequest.id", // идентификатор заявки
        "ServiceRequest.requester", // сотрудник, оформимвший заявку
        "ServiceRequest.status", // статус заявки
        "Condition.id", // идентификатор заболевания
        "Condition.code", // код заболевания
    ].join(","),
}

/**
 * информация о консилиумах на текущую дату
 * 1. сначала ищем идентификаторы
 * 2. потом все консилиумы с доп. ресурсами
 */
export class Appointments {

    /**
     * массив консилиумов
     */
    @observable items = [];

    /**
     * ошибка загрузки
     */
    @observable error = null;

    /**
     * флаг загрузки данных
     */
    @observable loading = false;

    /**
     * на входе - текущая дата
     */
    constructor(currentDate) {
        this.load(currentDate);
    }

    query = (currentDate) => {
        const momentDate = moment(currentDate);
        const params = {
            "date-before": momentDate.endOf("day").toISOString(),
            "date-after": momentDate.startOf("day").toISOString(),
            "output-format": "destinations",
            "count": 999
        }
        return params;
    }

    /**
     * загрузка данных
     */
    load = (currentDate) => {
        this.items = [];
        this.error = null;
        this.loading = true;
        basicRequestPost('/api/mm/appointments/$search', this.query(currentDate)).then(this.onLoad).catch(this.onError).finally(() => this.loading = false);
    }

    /**
     * при успешной загрузке данных
     */
    onLoad = ({data: response}) => {
        if( ! response.entry.length ) {
            return;
        }
        this.items = new Items(new Response(response));
    }

    /**
     * при ошибке загрузки данных
     */
    onError = (error) => {
        this.error = error;
        console.error("При загрузке информации о консилиумах произошла ошибка", this.error);
    }

}
