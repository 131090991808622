import { TextFilter } from "./TextFilter";
import { observable, reaction } from "mobx";
import { Mkb10Filter } from "./Mkb10Filter";
import { LimitFilter } from "./LimitFilter";
import { PeriodFilter } from "./PeriodFilter";
import { PaginationFilter } from "./PaginationFilter";
import { OrganizationFilter } from "MODEL_STORE/DataSource/Filters/OrganizationFilter";
import { MIN_CHAR_LENGTH } from '../const';
/**
 * таймаут перед поиском (в мс)
 */
const TIMEOUT_BEFORE_SEARCH = 500;

export const LOCAL_STORE_FILTER_KEY = "APPOINTMENTS_FILTERS";
export const LOCAL_STORE_FIELDS_KEY = "APPOINTMENTS_FIELDS";

export class Filters {

    /**
     * функция поиска
     */
    dataSearch = null;

    /**
     * таймаут
     */
    timeout = null;

    /**
     * фильтр по заболеванию, которое рассматривается на консилиуме
     */
    @observable mkb10 = new Mkb10Filter;

    /**
     * фильтр кол-ва результатов на странице
     */
    @observable limit = new LimitFilter();

    /**
     * фильтр по датам проведения консилиума
     */
    @observable period = new PeriodFilter();

    /**
     * фильтр по имени пациента в заявке
     */
    @observable patientName = new TextFilter();

    /**
     * фильтр по страницам с резальтатами
     */
    @observable pagination = new PaginationFilter();

    /**
     * фильтр по названию консилиума
     */
    @observable description = new TextFilter();

    /**
     * фильтр по организации, в которой проходит консилиум
     */
    @observable organization = new OrganizationFilter();

    /**
     * при создании объекта регистрируем реакции на изменение фильтров
     */
    constructor(periodType, dataSearch) {
        this.period.setType(periodType);
        this.dataSearch = dataSearch;

        // поиск по имени пациента, заявка которого рассматривается на консилиуме
        reaction(() => this.patientName.value, this.defferedTextSearch);
        // поиск по названию консилиума
        reaction(() => this.description.value, this.defferedTextSearch);
        // поиск коду заболевания, рассматриваемого на консилиуме
        reaction(() => this.mkb10.value, this.changePageOrSearch);
        // поиск с использованием ограничения на кол-во результатов на странице
        reaction(() => this.limit.count, this.changePageOrSearch);
        // поиск по месту проведения (организации) консилиума
        reaction(() => this.organization.value, this.changePageOrSearch);
        // при смене страницы сразу запускаем поиск данных
        reaction(() => this.pagination.page, this.dataSearch);
        // при смене дат периода
        reaction(() => this.period.dates, this.changePageOrSearch);

        this.loadFromLocalStore();
    }

    loadFromLocalStore = () => {
        let filters = localStorage.getItem(LOCAL_STORE_FILTER_KEY);
        if (!filters) return null;

        filters = JSON.parse(filters);

        this.patientName.value = filters.patientName.value;
        this.mkb10.value = filters.mkb10.value;
        this.mkb10.concepts = filters.mkb10.concepts;
        this.description.value = filters.description.value;
        this.period.dates = filters.period.dates;
        this.period.type = filters.period.type;
        this.organization.value = filters.organization.value;
        this.organization.resources = filters.organization.resources;

        setTimeout(this.changePageOrSearch);
    };

    deleteLocalStoreData = () => {
        localStorage.removeItem(LOCAL_STORE_FILTER_KEY);
    };

    /**
     * отложенный поиск по текстовым полям
     */
    defferedTextSearch = (value = "") => {
        if (value.trim().length == 0 || value.trim().length >= MIN_CHAR_LENGTH) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.changePageOrSearch, TIMEOUT_BEFORE_SEARCH);
        }
    }

    /**
     * отложенный поиск с использованием таймаута
     */
    defferedSearch = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.changePageOrSearch, TIMEOUT_BEFORE_SEARCH);
    }

    /**
     * если страница не первая, то делаем ее первой и ждем реакцию на ее изменение
     * в ином случае - сразу запускаем поиск данных
     */
    changePageOrSearch = () => {
        localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify(this));
        if(this.pagination.page != 1) {
            this.pagination.setPage(1);
        } else {
            this.dataSearch();
        }
    };

}
