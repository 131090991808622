import _get from "lodash/get";
import {observable} from 'mobx';
import {AuthStore} from "MODEL_STORE/DataSource/Stores/AuthStore";

export class OwnServiceRequests {

	@observable value = false;

	constructor() {
		this.value = AuthStore.currentUserData.activeRoleHasArmRole("arm_practitioner")
	}

	/**
	 * установка текстового значения
	 */
	changeValue = () => {
		this.value = !this.value;
	}

	initLocalStorage = (localStoreFieldsKey, paramKey) => {
		this.localStoreFieldsKey = localStoreFieldsKey;
		this.paramKey = paramKey;
	}

	setLocalStorage = () => {
		if (this.localStoreFieldsKey) {
			let filters = localStorage.getItem(this.localStoreFieldsKey);
			if (filters) {
				filters = JSON.parse(filters);
			} else {
				filters = {};
			}
			filters[this.paramKey] = this.value;
			localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
		}
	}
}
