import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Card } from "GUI_MAIN/Elements/Bootstrap/Card";
import { ServiceRequestsContent } from "./ServiceRequestsContent";
import {errorBoundary} from "GUI_MAIN/ErrorBoundary/ErrorBoundary";
import {EOLocale} from "eo-locale";
import { StyledCheckbox } from "GUI_MAIN/Elements/StyledCheckbox/index";

// TODO >div style below is workaround
const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 20px 16px 16px;
  min-height: 315px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 8px 0 #0000001A;

  p {
    margin-bottom: 0;
    cursor: pointer;
  }
  `;

const Title = styled.div`
  cursor: pointer;
  display: inline-block;
`;

const CheckBoxWrapper = styled.div`
    display: inline-block;
    /* margin-left: 300px; */
    float: right;
`;

const TitleWrapper = styled.div`
  display: block;
  width: 100%;
`;

@errorBoundary(null, null, null, null, "ServiceRequestSection")
@observer
export class ServiceRequestsSection extends React.PureComponent {

    render () {
      const label = <TitleWrapper>
          <Title onClick={this.props.showServiceRequests}><EOLocale.Text id={"SS001014"}/></Title>
          {/*<CheckBoxWrapper>*/}
          {/*    <StyledCheckbox*/}
          {/*        disabled={false}*/}
          {/*        label={<EOLocale.Text id={'SS904166'} />}*/}
          {/*        id="confirmationTypeCheckbox"*/}
          {/*        value={this.props.isMyRequests}*/}
          {/*        onChange={this.props.onMyRequestsChange}*/}
          {/*    />*/}
          {/*</CheckBoxWrapper>*/}
        </TitleWrapper>;
        return (
            <Wrapper>
                <Card label={label} headerBG="blue" contentGrow={'calc(100% - 48px)'} headerFlex={'1 1 100%'}>
                    <ServiceRequestsContent {...this.props}/>
                </Card>
            </Wrapper>
        )
    }
}
