import { observable } from "mobx";
import { getFormat } from "Locale/datetime";
/**
 * день на календаре
 */
export class Day {

    /**
     * день (например, 11)
     */
    day = null;

    /**
     * дата (например, 2020-04-01)
     */
    date = null;

    /**
     * сегодняшний день?
     */
    isToday = false;

    /**
     * функция выбора текущего дня
     */
    makeSelected = () => null;

    /**
     * день выбран на календаре?
     */
    @observable isSelected = false;

    /**
     * есть ли консилиумы на этот день?
     */
    @observable hasAppointments = false;

    /**
     * есть ли заявки на этот день?
     */
    @observable hasServiceRequests = false;

    /**
     * принимает ли участие в консилиумах текущий пользователь?
     */
    @observable hasOwnAppointments = false;

    /**
     * есть ли заявки в консилиумах текущего пользователя?
     */
    @observable hasOwnServiceRequests = false;

    /**
     * на входе объект MomentJs с датой, текущий день и выбранный
     */
    constructor(momentDate, todayDate, selectedDate, setSelectedDate) {
        this.day = momentDate.date();
        this.date =  momentDate.format(getFormat("DT000006"));
        this.isToday = this.date == todayDate;
        this.isSelected = this.date == selectedDate;
        this.makeSelected = () => setSelectedDate(this.date);
    }

}
