import { observable } from 'mobx';

export class TextFilter {

    /**
     * текстовое значение
     */
    @observable value = "";

    constructor(value = "") {
        this.value = value;
    }

    /**
     * установка текстового значения
     */
    setValue = (value = "") => {
        this.value = value;
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
