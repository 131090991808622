import React from "react";
import { observable } from "mobx";
import styled from 'styled-components';
import { observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import { AppointmentTitle } from "./AppointmentTitle";
import { AppointmentComment } from "./AppointmentComment";
import { AppointmentDetails } from "./AppointmentDetails";
import { AppointmentLocation } from "./AppointmentLocation";
import { AppointmentConditions } from "./AppointmentConditions";
import { AppointmentParticipants } from "./AppointmentParticipants";
import { AppointmentSlotCounters } from "./AppointmentSlotCounters";
import {tooltipChecker} from "GUI_MAIN/Utils/tooltipChecker";

const ConsultationWrapper = styled.div`
  margin: 0 2px 0 0;
  ${props =>
    props.isOver ?
        'border: 1px solid #337AB7;'
        :
        'border-bottom: 1px solid #D3D4D8;'
    };
  background-color: #FFF;
  border-radius: 2px;
  &:nth-child(n+3):last-child{
    margin-bottom: 100px;
  }`;

const TematicWrapperInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px 0 16px;`;

const PacientAndMembersSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px 12px 20px;
`;

const Organization = styled.div`
  padding: 0 16px;
  font-family: ${props => props.theme.OpenSansRegular};
  color: ${props => props.theme.lightenMainTextColor};
  font-size: ${props => props.theme.footNoteFontSize};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Location = styled.div`
  padding: 0 16px;
  font-family: ${props => props.theme.OpenSansRegular};
  color: ${props => props.theme.lightenMainTextColor};
  font-size: ${props => props.theme.footNoteFontSize};
`;

@observer
export class AppointmentItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this.organization = React.createRef();
    }

    @observable details = {
        show: false,
        toggle: () => this.details.show = ! this.details.show
    }
    componentDidMount() {
        tooltipChecker("text", [this.organization]);
      ReactTooltip.rebuild();
    }
    render() {
        const {
          level,
          comment,
          conditions,
          organization,
          slotCounters,
          participants,
        } = this.props.item;
        return (
            <ConsultationWrapper>
                <TematicWrapperInner>
                    <AppointmentTitle item={this.props.item}/>
                    <AppointmentDetails item={this.props.item} details={this.details}/>
                </TematicWrapperInner>
                <Organization ref={this.organization}>{organization.name}</Organization>
                <Location>{level}</Location>
                { this.details.show && <AppointmentConditions conditions={conditions}/> }
                <AppointmentLocation organization={organization} show={this.details.show}/>
                <AppointmentSlotCounters counters={slotCounters} createServiceRequest={this.props.createServiceRequest}/>
                <PacientAndMembersSection>
                  {
                    this.details.show &&
                    <AppointmentParticipants participants={participants}/>
                  }
                </PacientAndMembersSection>
                <AppointmentComment comment={comment} show={this.details.show}/>
            </ConsultationWrapper>
        )
    }
}
