import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import MKBCode from "GUI_MAIN/Elements/MKBCode";

const ConditionListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 16px 0;`;

@observer
export class AppointmentConditions extends React.PureComponent {
    render() {
        return (
            this.props.conditions.length ?
                <ConditionListWrapper>
                    { this.props.conditions.map(condition => (
                        <MKBCode
                            key={condition.id}
                            code={condition.code}
                            margin={'0 5px 5px 0'}
                            dataTip={condition.name}
                            dataFor={`condition-tooltip-${condition.id}`}
                        />
                    ))}
                </ConditionListWrapper> :
                null
        )
    }
}
