import React from "react";
import styled from "styled-components";
import BigConsilium from "GUI_MAIN/icons/BigConsilium";
import { EOLocale } from "eo-locale";

const IconWrapper = styled.div`
  width: 128px;
  height: 128px;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 16px;
  background-color: rgba(209, 233, 250, 0.25);
  svg{
  width: 100%;
  height: auto;
  }
`;

const NoAppointmentItem = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px`;

const NoItemText = styled.div`
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.bigFontSize};
  color: ${props => props.theme.mainRedColor}`;

export class AppointmentsError extends React.PureComponent {
    render() {
        return (
            <NoAppointmentItem>
                <IconWrapper>
                    <BigConsilium/>
                </IconWrapper>
                <NoItemText><EOLocale.Text id={"TT022350"} /></NoItemText>
            </NoAppointmentItem>
        );
    }
}
