import moment from "moment";
import { observable } from "mobx";
import { Item } from "../ServiceRequests/Item";
import { UrlSearchParams } from "GUI_MAIN/Utils/UrlSearchParams";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";
import Logger from "../../../../../logger";
import { ACCESS_RIGHT_ACTION as ACTION } from "MODEL_STORE/DataSource/Constants/user_access_rights";
import { ACCESS_RIGHT_OBJECT as OBJECT } from "MODEL_STORE/DataSource/Constants/user_access_rights";
import { ACCESS_RIGHT_INCLUDES as INCLUDES } from "MODEL_STORE/DataSource/Constants/user_access_rights";

/**
 * информация о заявках на текущую дату
 */
export class ServiceRequests {

    /**
     * массив заявок
     */
    @observable items = [];

    /**
     * ошибка загрузки
     */
    @observable error = null;

    /**
     * флаг загрузки данных
     */
    @observable loading = false;

    /**
     * на входе - текущая дата
     */
    constructor(currentDate) {
        this.load(currentDate);
    }

    /**
     * параметры запроса
     */
    queryData = (currentDate) => {
        const momentDate = moment(currentDate);
        const params = {
            "count": 100,
            "appointment-end": momentDate.endOf("day").toISOString(),
            "appointment-start": momentDate.startOf("day").toISOString(),
        }
        // if ( ! AuthStore.currentUserData.isRoot() ) {
        //     const isListViewInvited = AuthStore.currentUserData.userHasAccessRights(OBJECT.SERVICEREQUEST_LIST, ACTION.VIEW, INCLUDES.INVITED);
        //     const isListViewAllowed = AuthStore.currentUserData.userHasAccessRights(OBJECT.SERVICEREQUEST_LIST, ACTION.VIEW, INCLUDES.NULL);
        //     if ( isListViewInvited && ! isListViewAllowed ) {
        //         params["participant-actor-id"] = AuthStore.currentUserData.active_role_id;
        //     }
        // }
        // if ( ! AuthStore.currentUserData.isRoot() ) {
        //     params["organization-id"] = AuthStore.currentUserData.active_organization_id;
        // }
        return params;
    }

    /**
     * загрузка данных
     */
    load = (currentDate) => {
        this.items = [];
        this.error = null;
        this.loading = true;
        basicRequestPost('/api/mm/service-requests/list-with-filters/', this.queryData(currentDate)).then(this.onLoad, this.onError).finally(() => this.loading = false);
    };

    /**
     * при успешной загрузке данных
     */
    onLoad = ({data: response}) => {
        if ( ! Array.isArray(response.data) ) {
            throw "При запросе к AidboxQuery 'service-request-list-search' произошла ошибка";
        }
        this.items = response.data.map(item => {
            return new Item({
                slot: item.slot,
                compositions: [],
                patient: item.patient,
                conditions: [...item.conditions],
                appointment: item.appointment,
                organization: item.organization,
                serviceRequest: item.servicerequest,
            })
        });
    }

    /**
     * при ошибке загрузки данных
     */
    onError = (error) => {
        this.error = error;
        console.error("При загрузке информации о заявках произошла ошибка", this.error);
    }

}
