import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import RefreshIcon from "GUI_MM/Icons/RefreshIcon";

const TitleSection = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  font-family: ${props=>props.theme.OpenSansSemiBold};
  font-size: ${props => props.theme.eighteenFontSize}`;

const DateInfo = styled.div`
  display: flex;
  margin: 0 5px 0 0`;

const IconWrapper = styled.div`
  transform: ${props => props.rotateArrow ? 'rotate(180deg)' : 'rotate(0deg)'};
  position: relative;
  cursor: pointer;
  min-width: ${props=> props.width? props.width : '20px'};
  max-width: ${props=> props.width? props.width : '20px'};
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    width:100%;
    height: auto;
  }`;

const TimeSection = styled.span`
  font-family: ${props=>props.theme.OpenSansRegular}
`;

const TematicHeader = styled.div`
  flex-grow: 1;
  a{
    color: ${props=>props.theme.darkMainTextColor};
    &:hover{
     color: ${props=>props.theme.darkMainTextColor};
     text-decoration: none;
    }
  }`;

@observer
export class AppointmentTitle extends React.PureComponent {
    render() {
        const {
            startTime,
            endTime,
            link,
            name
        } = this.props.item;
        return (
            <TitleSection>
                <DateInfo>
                    <TimeSection>
                      {startTime} - {endTime}
                    </TimeSection>
                </DateInfo>
                <TematicHeader>
                    <Link to={link}>
                        {name}
                    </Link>
                </TematicHeader>
            </TitleSection>
        )
    }
}
