import _get from "lodash/get";
import { observable } from "mobx";
import { REQUEST_INTENT } from "MODEL_STORE/FHIR/CodeSystem/RequestIntent";
import { REQUEST_STATUS } from "MODEL_STORE/FHIR/CodeSystem/RequestStatus";
import {EOLocale} from "eo-locale";
import React from 'react';

/**
 * В обработке - набор статусов
 * 1. создана
 * 2. на согласовании
 * 3. Готова для рассмотрения
 * 4. Возвращена на доработку
 */

const STATUSES = {
    // Убрано в рамках https://miramedix.atlassian.net/browse/MIRA-293
    // processing: {
    //     name: <EOLocale.Text id={"SS014003"}/>,
    //     query: {
    //         intent: [
    //             REQUEST_INTENT.PLAN,
    //             REQUEST_INTENT.ORDER,
    //             REQUEST_INTENT.PROPOSAL,
    //         ].join(","),
    //         "status:not": [
    //             REQUEST_STATUS.REVOKED,
    //             REQUEST_STATUS.COMPLETED,
    //             REQUEST_STATUS.ENTERED_IN_ERROR,
    //         ],
    //     }
    // },
    created: {
        name: <EOLocale.Text id={"SS014005"}/>,
        query: {
            intent: REQUEST_INTENT.PROPOSAL,
            status: REQUEST_STATUS.DRAFT,
        }
    },
    matching: {
        name: <EOLocale.Text id={"SS014006"}/>,
        query: {
            intent: REQUEST_INTENT.PLAN,
            status: REQUEST_STATUS.ON_HOLD,
        }
    },
    approved: {
        name: <EOLocale.Text id={"SS014007"}/>,
        query: {
            intent: REQUEST_INTENT.ORDER,
            status: REQUEST_STATUS.ACTIVE,
        }
    },
    canceled: {
        name: <EOLocale.Text id={"SS014008"}/>,
        query: {
            intent: REQUEST_INTENT.PROPOSAL,
            status: REQUEST_STATUS.ON_HOLD,
        }
    },
    break: {
        name: <EOLocale.Text id={"SS014009"}/>,
        query: {
            intent: [
                REQUEST_INTENT.PLAN,
                REQUEST_INTENT.ORDER,
                REQUEST_INTENT.PROPOSAL,
            ].join(","),
            status: REQUEST_STATUS.REVOKED,
        }
    },
    deleted: {
        name: <EOLocale.Text id={"SS014010"}/>,
        query: {
            intent: REQUEST_INTENT.PROPOSAL,
            status: REQUEST_STATUS.ENTERED_IN_ERROR,
        }
    },
    completed: {
        name: <EOLocale.Text id={"SS014011"}/>,
        query: {
            intent: REQUEST_INTENT.ORDER,
            status: REQUEST_STATUS.COMPLETED,
        }
    },
};

/**
 * конвертер типа страницы в тип заявки
 */
const TYPE_TO_VALUE = {
    f_back: "canceled",
    f_on_hold: "matching",
    f_not_close: "processing"
}

export class StatusFilter {

    /**
     * ключ статуса заявки
     */
    @observable value = "";

    constructor(dataType = "") {
        if(Object.keys(TYPE_TO_VALUE).includes(dataType)) {
            this.value = TYPE_TO_VALUE[dataType];
        }
    }

    /**
     * данные для поиска по статусу
     */
    get query() {
        return _get(STATUSES, [this.value, 'query']);
    }

    /**
     * выбранное значение статуса заявки для React-Select
     */
    get option() {
        return this.options.find(option => option.value == this.value) || "";
    }

    /**
     * возможные значения статуса заявки для React-Select
     */
    get options() {
        return Object.keys(STATUSES)
            .filter(e => e != 'deleted') // MIRA-2308
            .map(key => ({
                value: key,
                label: _get(STATUSES, [key, 'name'])
            }))
    }

    /**
     * установка значения статуса заявки
     */
    setOption = (selected) => {
        if(selected) {
            const value = _get(selected, "value");
            if(Object.keys(STATUSES).includes(value)) {
                this.value = value;
            }
        } else {
            this.value = ""
        }
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
