import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import Preloader from "GUI_MAIN/Elements/Preloader";
import { AppointmentsList } from "./AppointmentsList";
import { AppointmentsEmpty } from "./AppointmentsEmpty";
import { AppointmentsError } from "./AppointmentsError";

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;

@observer
export class Appointments extends React.PureComponent {
    getContentComponent = (items, error) => {
        if( error ) {
            return <AppointmentsError/>;
        }
        if( ! items.length ) {
            return <AppointmentsEmpty />;
        }
        return (
            <AppointmentsList
                items={items}
                createServiceRequest={this.props.createServiceRequest}/>
        )
    }
    render () {
        const {
            error,
            items,
            loading
        } = this.props.appointments;
        if ( loading ) {
            return <Preloader />;
        }
        return (
            <ContentWrapper>
                { this.getContentComponent(items, error) }
            </ContentWrapper>
        )
    }
}
