import { TextFilter } from "./TextFilter";
import { observable, reaction } from "mobx";
import { Mkb10Filter } from "./Mkb10Filter";
import { OrderFilter } from "./OrderFilter";
import { NumberFilter } from "./NumberFilter";
import { PeriodFilter } from "./PeriodFilter";
import { StatusFilter } from "./StatusFilter";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";
import { OrganizationFilter } from "MODEL_STORE/DataSource/Filters/OrganizationFilter";
import {OwnServiceRequests} from "./OwnServiceRequests"
import {PagePaginationStore} from "MODEL_STORE/DataSource/Stores/PagePaginationStore";
import moment from "moment";

const REACTION_TIMEOUT = 500;
export const LOCAL_STORE_FILTER_KEY = "SERVICEREQUEST_FILTERS";
export const LOCAL_STORE_FIELDS_KEY = "SERVICEREQUEST_FIELDS";

export class Filters {

    /**
     * фильтр имени пациента
     */
    @observable name = new TextFilter();

    /**
     * фильтр по номеру заявки
     */
    @observable caseNumber = new TextFilter();

    /**
     * фильтр текущей страницы
     */
    @observable page = new NumberFilter(1);

    /**
     * фильтр кол-ва страниц
     */
    @observable pages = new NumberFilter(1);

    /**
     * фильтр кол-ва загружаемых ресурсов
     */
    @observable limit = new NumberFilter(15);

    /**
     * фильтр имени пациента
     */
    @observable mkb10 = new Mkb10Filter();

    /**
     * сортировка
     */
    @observable order = new OrderFilter();

    /**
     * фильтр периода проведения консилиумов
     */
    @observable period = null;

    /**
     * фильтр статуса заявки
     */
    @observable status = null;

    /**
     * комплексный фильтр места проведения консилиума
     * состоит из идентификаторов, разделенных запятой
     */
    @observable location = null;

    @observable ownServiceRequests = new OwnServiceRequests();

    /**
     * фильтр организаций мест проведения консилиума
     */
    @observable organization = new OrganizationFilter();
    @observable requesterOrganization = new OrganizationFilter();

    // Код раздела сайта, для которого применяется пагинация
    pagePaginationStore = new PagePaginationStore(LOCAL_STORE_FILTER_KEY);

    constructor(dataType = "", search = () => null) {
        this.status = new StatusFilter(dataType);
        this.period = new PeriodFilter(dataType);

        this.limit.setPagePaginationStore(this.pagePaginationStore);
        this.limit.setValue(this.pagePaginationStore.getPageCount());

        let searchTimeout = null;
        const searchAfterTimeout = () => {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(this.setFirstPageOrSearch, REACTION_TIMEOUT);
        };
        reaction(() => this.page.value, search);
        reaction(() => this.limit.value, this.setFirstPageOrSearch);
        reaction(() => this.location, this.setFirstPageOrSearch);
        reaction(() => this.order.value, this.setFirstPageOrSearch, { delay: 100 });

        this.loadFromLocalStore();
    }

    loadFromLocalStore = () => {
        let filters = localStorage.getItem(LOCAL_STORE_FILTER_KEY);
        if (!filters) return null;
        filters = JSON.parse(filters);

        this.period.setDates(moment(filters.periodBeforeDate), moment(filters.periodAfterDate))
        this.organization.setOption({value: filters.organization});
        this.requesterOrganization.setOption({value: filters.requesterOrganization});
        this.status.setOption({value: filters.status});
        this.name.setValue(filters.name);
        this.caseNumber.setValue(filters.caseNumber);
        this.mkb10.setOption({value: filters.mkb10});
        this.mkb10.search(filters.mkb10)
        this.ownServiceRequests.value = filters.ownServiceRequests
    };

    loadFieldsFromLocalStore = () => {
        this.period.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'period');
        this.organization.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'organization');
        this.requesterOrganization.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'requesterOrganization');
        this.status.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'status');
        this.name.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'name');
        this.caseNumber.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'caseNumber');
        this.mkb10.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'mkb10');
        this.ownServiceRequests.initLocalStorage(LOCAL_STORE_FIELDS_KEY, 'ownServiceRequests');

        let filters = localStorage.getItem(LOCAL_STORE_FIELDS_KEY);
        if (!filters) return null;
        filters = JSON.parse(filters);
        if (filters.period) {
            this.period.setDates(moment(filters.period.before), moment(filters.period.after))
        }
        if (filters.organization !== undefined)
            this.organization.setOption({value: filters.organization});
        if (filters.requesterOrganization !== undefined)
            this.requesterOrganization.setOption({value: filters.requesterOrganization});
        if (filters.status !== undefined)
            this.status.setOption({value: filters.status});
        if (filters.name !== undefined)
            this.name.setValue(filters.name);
        if (filters.caseNumber !== undefined)
            this.caseNumber.setValue(filters.caseNumber);
        if (filters.mkb10 !== undefined) {
            this.mkb10.setOption({value: filters.mkb10});
            this.mkb10.search(filters.mkb10)
        }
        if (filters.ownServiceRequests !== undefined)
            this.ownServiceRequests.value = filters.ownServiceRequests
    };

    setFirstPageOrSearch = () => {
        localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify({
            periodBeforeDate: this.period.beforeDate,
            periodAfterDate: this.period.afterDate,
            organization: this.organization.value,
            requesterOrganization: this.requesterOrganization.value,
            status: this.status.value,
            name: this.name.value,
            caseNumber: this.caseNumber.value,
            mkb10: this.mkb10.value,
            ownServiceRequests: this.ownServiceRequests.value,
        }));
        return this.page.value != 1 ? this.page.setValue(1) : search();
    };

    deleteLocalStoreData = () => {
        localStorage.removeItem(LOCAL_STORE_FILTER_KEY);
        localStorage.removeItem(LOCAL_STORE_FIELDS_KEY);
        this.pagePaginationStore.clearPageCount();
    };

    /**
     * поиск мест проведения на основе идентификатора организации
     * для использования фильтра _has:Appointment:based-on:location
     */
    setLocation = (organizationId = "") => {
        if( ! organizationId ) {
            return this.resetLocation();
        }
        basicRequestPost("/Location/_search", {
            "_elements": "id",
            "organization": organizationId
        }).then(response => {
            // undefined тут не просто так
            // он означает, что не найдено ни одно место проведения с указанной организацией
            // это важно при фильтрации заявок, на основе места проведения консилиума
            this.location = response.data.entry.map(entry => entry.resource.id).join(",") || "undefined";
        });
    }

    /**
     * сброс фильтра места проведения
     */
    resetLocation = () => {
        if( this.location ) {
            this.location = null;
        } else {
            // this.location еще может быть null, необходимо запустить поиск после обновления значения МО
            this.setFirstPageOrSearch();
        }
    }

}
