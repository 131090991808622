import { TextFilter } from "./TextFilter";
import { observable, reaction } from "mobx";
import { OrderFilter } from "./OrderFilter";
import { Mkb10Filter } from "./Mkb10Filter";
import { NumberFilter } from "./NumberFilter";
import { PeriodFilter } from "./PeriodFilter";
import { StatusFilter } from "./StatusFilter";
import { OrganizationFilter } from "MODEL_STORE/DataSource/Filters/OrganizationFilter";
import {OwnServiceRequests} from "./OwnServiceRequests"
import {PagePaginationStore} from "MODEL_STORE/DataSource/Stores/PagePaginationStore";
import moment from "moment";

const REACTION_TIMEOUT = 500;
export const LOCAL_STORE_FILTER_KEY = "PROTOCOLS_FILTERS";
export const LOCAL_STORE_FIELDS_KEY = "PROTOCOLS_FIELDS";

export class Filters {

    /**
     * фильтр имени пациента
     */
    @observable name = new TextFilter();

    /**
     * фильтр по полису ОМС
     */
    @observable oms = new TextFilter();

    /**
     * фильтр текущей страницы
     */
    @observable page = new NumberFilter(1);

    /**
     * фильтр кол-ва страниц
     */
    @observable pages = new NumberFilter(1);

    /**
     * фильтр кол-ва загружаемых ресурсов
     */
    @observable limit = new NumberFilter(15);

    /**
     * фильтр имени пациента
     */
    @observable mkb10 = new Mkb10Filter();

    /**
     * фильтр периода проведения консилиумов
     */
    @observable period = null;

    /**
     * фильтр статуса заявки
     */
    @observable status = null;

    /**
     * фильтр по номеру протокола
     */
    @observable identifier = new TextFilter();

    /**
     * фильтр места проведения консилиума
     */
    @observable organization = new OrganizationFilter();
    @observable requesterOrganization = new OrganizationFilter();

    /**
     * фильтр сортировки
     */
    @observable order = new OrderFilter();

    AuthStore = null;
    @observable ownServiceRequests = new OwnServiceRequests();

    // Код раздела сайта, для которого применяется пагинация
    pagePaginationStore = new PagePaginationStore(LOCAL_STORE_FILTER_KEY);

    /**
     * инициализация фильтров
     */
     constructor(dataType = "", search = () => null, AuthStore = null) {
        this.limit.setPagePaginationStore(this.pagePaginationStore);
        this.limit.setValue(this.pagePaginationStore.getPageCount());
        this.status = new StatusFilter(dataType);
        this.period = new PeriodFilter(dataType);
        this.AuthStore = AuthStore

        let searchTimeout = null;
        const searchAfterTimeout = () => {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(this.setFirstPageOrSearch, REACTION_TIMEOUT);
        };
        reaction(() => this.page.value, search);

        // отложенный поиск по полису ОМС
        reaction(() => this.oms.value, searchAfterTimeout);

        // немедленный поиск для остальных значений
        reaction(() => this.limit.value, this.setFirstPageOrSearch);
        reaction(() => this.order.direction, this.setFirstPageOrSearch);

        this.loadFromLocalStore();
    }


    loadFromLocalStore = () => {
        let filters = localStorage.getItem(LOCAL_STORE_FILTER_KEY);
        if (!filters) return null;
        filters = JSON.parse(filters);

        this.period.setDates(moment(filters.periodBeforeDate), moment(filters.periodAfterDate))
        this.organization.setOption({value: filters.organization});
        this.requesterOrganization.setOption({value: filters.requesterOrganization});
        this.status.setOption({value: filters.status});
        this.name.setValue(filters.name);
        this.identifier.setValue(filters.identifier);
        this.mkb10.setOption({value: filters.mkb10});
        this.mkb10.search(filters.mkb10)
        this.ownServiceRequests.value = filters.ownServiceRequests
    };

    loadFieldsFromLocalStore = () => {
        this.period.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "period");
        this.organization.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "organization");
        this.requesterOrganization.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "requesterOrganization");
        this.status.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "status");
        this.name.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "name");
        this.identifier.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "identifier");
        this.mkb10.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "mkb10");
        this.ownServiceRequests.initLocalStorage(LOCAL_STORE_FIELDS_KEY, "ownServiceRequests");

        let filters = localStorage.getItem(LOCAL_STORE_FIELDS_KEY);
        if (!filters) return null;
        filters = JSON.parse(filters);
        if (filters.period)
            this.period.setDates(moment(filters.period.before), moment(filters.period.after))
        if (filters.organization !== undefined)
          this.organization.setOption({value: filters.organization});
        if (filters.requesterOrganization !== undefined)
          this.requesterOrganization.setOption({value: filters.requesterOrganization});
        if (filters.status !== undefined)
          this.status.setOption({value: filters.status});
        if (filters.name !== undefined)
          this.name.setValue(filters.name);
        if (filters.identifier !== undefined)
          this.identifier.setValue(filters.identifier);
        if (filters.mkb10) {
            this.mkb10.setOption({value: filters.mkb10});
            this.mkb10.search(filters.mkb10)
        }
        if (filters.ownServiceRequests !== undefined)
          this.ownServiceRequests.value = filters.ownServiceRequests
    };

    setFirstPageOrSearch = () => {
        localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify({
          periodBeforeDate: this.period.beforeDate,
          periodAfterDate: this.period.afterDate,
          organization: this.organization.value,
          requesterOrganization: this.requesterOrganization.value,
          status: this.status.value,
          name: this.name.value,
          identifier: this.identifier.value,
          mkb10: this.mkb10.value,
          ownServiceRequests: this.ownServiceRequests.value,
        }));
        return this.page.value !== 1 ? this.page.setValue(1) : search();
    };

    deleteLocalStoreData = () => {
        localStorage.removeItem(LOCAL_STORE_FILTER_KEY);
        localStorage.removeItem(LOCAL_STORE_FIELDS_KEY);
        this.pagePaginationStore.clearPageCount();
    }

}
