import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import PractitionerCard from "../../../../../Elements/PractitionerCard";
import {locales} from "Locale";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {Translator} from "eo-locale";

const MembersSection = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 8px;
  margin-top: 12px;
`;

@observer
export class AppointmentParticipants extends React.PureComponent {
    render() {
        const {
            experts,
            chairman,
            secretary,
            consultants
        } = this.props.participants;

        const translator = new Translator(HeaderStore.locale, locales);

        return (
            <MembersSection>
                <PractitionerCard
                    show={true}
                    roles={"Председатель"}
                    position={"SS001023"}
                    name={chairman ? chairman.name : translator.translate('SS9035836')}/>
                <PractitionerCard
                    show={true}
                    roles={"Секретарь"}
                    position={"SS001024"}
                    name={secretary ? secretary.name : translator.translate('SS9035836')}/>
                { experts.map((expert, key) => (
                    <PractitionerCard
                        key={key}
                        roles={"Эксперт"}
                        name={expert.name}
                        show={true}
                        position={expert.typeText ? expert.typeText : expert.position}/>
                )) }
                { consultants.map((consultant, key) => (
                    <PractitionerCard
                        key={key}
                        roles={"Консультант"}
                        name={consultant.name}
                        show={true}
                        position={consultant.typeText ? consultant.typeText : consultant.position}/>
                )) }
            </MembersSection>
        )
    }
}
