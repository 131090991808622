import { observable } from 'mobx';

export class OwnServiceRequests {

    /**
     * текстовое значение
     */
    @observable value = false;

    constructor(value = false) {
        this.value = value;
     }

    /**
     * установка текстового значения
     */
    changeValue = () => {
        this.value = !this.value;
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }
    
    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
