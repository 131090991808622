import { observable } from 'mobx';

export class NumberFilter {

    /**
     * значение
     */
    @observable value = 0;

    pagePaginationStore;

    constructor(value = 0) {
        this.setValue(value);
    }

    setPagePaginationStore = pagePaginationStore => {
        this.pagePaginationStore = pagePaginationStore;
    }

    /**
     * установка значения
     */
    setValue = (value = 0) => {
        this.value = value;
        if (this.pagePaginationStore) {
            this.pagePaginationStore.setPageCount(value);
        }
    }

}
