import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

const DayWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  color: ${props => props.isToday ? props.theme.whiteColor :
          (props.isOutsideCurrentMonth ? props.theme.outsideMonthColor : props.theme.mediumTextColor)};
  border: ${props => props.isSelected ? '1px solid' + props.theme.mainDarkBlueColor : '1px solid transparent'};
  background-color: ${ props => props.isToday ? props.theme.bgColorToday : (props.hasOwnAppointments ? props.theme.bgColorAppointments : 'none')};
  cursor: ${ props => props.isSelected ? 'default' : 'pointer'};
  pointer-events: ${ props => props.isSelected ? 'none' : 'all'};

  &:hover {
      color: ${props => props.theme.mediumTextColor};
      ${props => !props.isSelected && 'background-color: #E9F5FC'};
  }

  &:active {
      border: ${props => !props.isSelected && '1px solid ' + props.theme.mainDarkBlueColor};
  }
`;

const PointWrapper = styled.div`
    position: absolute;
    display: flex;
    gap: 4px;
    top: 4px;
`;

const Point = styled.div`
    background-color: ${props => props.blue && props.theme.blueAppointmentItem || props.green && props.theme.greenAppointmentItem || props.orange && props.theme.orangeAppointmentItem || props.theme.defaultAppointmentItem};
    border-radius: 50px;
    width: 4px;
    height: 4px;
`;

@observer
export class MonthDay extends React.PureComponent {
    render () {
        const {
            day,
            isToday,
            isSelected,
            makeSelected,
            hasAppointments,
            hasServiceRequests,
            hasOwnAppointments,
            hasOwnServiceRequests,
        } = this.props.day;

        const isOutsideCurrentMonth = this.props.day.date.split('-')[1] !== this.props.today.split('-')[1];

        const dayWrapperProps = {
            isToday,
            isSelected,
            hasOwnAppointments,
            isOutsideCurrentMonth,
            onClick: makeSelected,
        }
        return (
            <DayWrapper {...dayWrapperProps}>
                {/*{console.log(day)}*/}
                <PointWrapper>
                    { hasOwnAppointments && <Point blue/> }
                    { hasOwnServiceRequests && <Point green/> }
                    { /* hasServiceRequests && <Point orange/> */ }
                    { /* hasAppointments && <Point/> */ }
                </PointWrapper>
                {day}
            </DayWrapper>
        )
    }
}
