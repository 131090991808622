import moment from 'moment';
import { observable } from 'mobx';
import { getFormat } from "Locale/datetime";

/**
 * формат даты
 */
const DATE_FORMAT = "YYYY-MM-DD";

/**
 * Тип по умолчанию (все консилиумы)
 */
const DEFAULT_PERIOD_TYPE = 'f_all';

/**
 * возможные типы периодов
 */
const PERIOD_TYPES = {
    f_all: {
        title: 'SS011001',
        dates: {
            after: moment().format(getFormat("DT000006")),
            before: moment().add(2, 'month').format(getFormat("DT000006")),
        }
    },
    f_future: {
        title: 'SS00081',
        dates: {
            after: moment().add(1, 'day').format(getFormat("DT000006")),
            before: moment().add(1, 'day').add(1, 'year').format(getFormat("DT000006"))
        }
    },
    f_archive: {
        title: 'SS00082',
        dates: {
            after: moment().subtract(1, 'day').subtract(1, 'year').format(getFormat("DT000006")),
            before: moment().subtract(1, 'day').format(getFormat("DT000006"))
        }
    }
}

export class PeriodFilter {
    /**
     * тип периода (все, архив, предстоящие)
     */
    @observable type = DEFAULT_PERIOD_TYPE;

    /**
     * даты проведения консилиумов
     */
    @observable dates = PERIOD_TYPES[DEFAULT_PERIOD_TYPE].dates;

    /**
     * название фильтра
     */
    get title() {
        return PERIOD_TYPES[this.type].title;
    }

    /**
     * установка типа возвращаемых результатов (архив, предстоящие)
     */
    setType = (type) => {
        const newType = Object.keys(PERIOD_TYPES).includes(type) ? type : DEFAULT_PERIOD_TYPE;
        if(this.type == newType) {
            return false; // не меняем состояние, если тип не изменился
        }
        this.type = newType;
        this.dates = {
            after: PERIOD_TYPES[this.type].dates.after,
            before: PERIOD_TYPES[this.type].dates.before
        }
    }

    /**
     * дата проведения первого консилиума
     */
    get afterDate() {
        return this.dates.after ? moment(this.dates.after) : moment();
    }

    /**
     * дата проведения последнего консилиума
     */
    get beforeDate() {
        return this.dates.before ? moment(this.dates.before) : moment();
    }

    /**
     * установка дат
     */
    setDates = (firstDate, secondDate) => {
        this.dates = {
            after: moment.min(firstDate, secondDate).startOf('day').format(getFormat("DT000006")),
            before: moment.max(firstDate, secondDate).endOf('day').format(getFormat("DT000006")),
        }
    }

}
