/**
 * удаление копий ресурсов протокола из локального хранилища
 * https://jira.mmdx.ru/browse/FCSPOK-54
 */
export const removeProtocolResourcesFromLocalStorage = () => {
    for ( let localStorageKey in localStorage ) {
        if ( localStorageKey.substring(0, 9) === "PROTOCOL-" ) {
            localStorage.removeItem(localStorageKey);
        }
    }
};