import React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {EOLocale} from "eo-locale";

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoItemText = styled.div`
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.bigFontSize};
  color: ${props => props.theme.mediumTextColor};
`;


@inject('AuthStore')
@observer
export class ServiceRequestsEmpty extends React.PureComponent {
    render() {
        return (
            <Wrapper>
                <NoItemText><EOLocale.Text id={"SS001026"}/></NoItemText>
            </Wrapper>
        )
    }
}
