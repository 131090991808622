import moment from "moment";
import { observable } from "mobx";
import { getFormat } from "Locale/datetime";

/**
 * фильтры дат проведения консилиума
 */
export class PeriodFilter {

    /**
     * даты проведения консилиумов
     */
    @observable dates = {
        after: moment().startOf("day").toISOString(),
        before: moment().add(14, "day").endOf("day").toISOString()
    }

    /**
     * инициализация
     */
    constructor(dataType = "") {
        if(dataType == "f_archive") {
            this.dates = {
                after: moment().subtract(1, "year").startOf("day").toISOString(),
                before: moment().endOf("day").toISOString()
            }
        }
    }

    /**
     * дата проведения первого консилиума
     */
    get afterDate() {
        return this.dates.after ? moment(this.dates.after) : moment();
    }

    /**
     * дата проведения последнего консилиума
     */
    get beforeDate() {
        return this.dates.before ? moment(this.dates.before) : moment();
    }

    /**
     * установка дат
     */
    setDates = (firstDate, secondDate) => {
        this.dates = {
            after: moment.min(firstDate, secondDate).startOf("day").format(getFormat("DT000006")),
            before: moment.max(firstDate, secondDate).endOf("day").format(getFormat("DT000006"))
        }
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.dates;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
