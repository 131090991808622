import _get from "lodash/get";
import { observable } from "mobx";
import {
    PROTOCOL_COMPOSITION_INTEGRATION,
    PROTOCOL_COMPOSITION_STATUS,
    PROTOCOL_COMPOSITION_STATUS_COMBINED
} from "MODEL_STORE/FHIR/CodeSystem/ProtocolComposition";
import {EOLocale} from "eo-locale";
import React from "react";
import {CustomVersion} from "MODEL_STORE/DataSource/Stores/CustomVersion";
import {PROTOCOL_ELECTRONIC_SIGN_MODE} from "../../../../../../MODEL_STORE/DataSource/Stores/CustomVersion";

const STATUSES_DEFAULT = {
    [PROTOCOL_COMPOSITION_STATUS_COMBINED.IN_WORK.value]: {
        name: PROTOCOL_COMPOSITION_STATUS_COMBINED.IN_WORK.name,
        query: {
            status: PROTOCOL_COMPOSITION_STATUS.PRELIMINARY,
            "integration-status": PROTOCOL_COMPOSITION_INTEGRATION.PLAN
        }
    },
    [PROTOCOL_COMPOSITION_STATUS_COMBINED.CANCELED.value]: {
        name: PROTOCOL_COMPOSITION_STATUS_COMBINED.CANCELED.name,
        query: {
            status: PROTOCOL_COMPOSITION_STATUS.ENTERED_IN_ERROR,
            "integration-status": PROTOCOL_COMPOSITION_INTEGRATION.PLAN
        }
    },
    [PROTOCOL_COMPOSITION_STATUS_COMBINED.TO_SIGN.value]: {
        name: PROTOCOL_COMPOSITION_STATUS_COMBINED.TO_SIGN.name,
        query: {
            status: PROTOCOL_COMPOSITION_STATUS.PRELIMINARY,
            "integration-status": PROTOCOL_COMPOSITION_INTEGRATION.ORDER,
        }
    }
};

// В рамках задачи TB-1127. Если параметр VALIDATED не передан, не включать его в запрос
// if (!PROTOCOL_COMPOSITION_INTEGRATION.VALIDATED) {
//     STATUSES_DEFAULT[PROTOCOL_COMPOSITION_STATUS_COMBINED.READY.value].query["integration-status"] = PROTOCOL_COMPOSITION_INTEGRATION.ORDER
// }

const STATUSES_SINGLE = {
    [PROTOCOL_COMPOSITION_STATUS_COMBINED.PUBLISHED.value]: {
        name: PROTOCOL_COMPOSITION_STATUS_COMBINED.PUBLISHED.name,
        query: {
            status: PROTOCOL_COMPOSITION_STATUS.FINAL,
            "integration-status": PROTOCOL_COMPOSITION_INTEGRATION.COMPLETE,
        }
    }
};

const STATUSES_MULTIPLE = {
    [PROTOCOL_COMPOSITION_STATUS_COMBINED.PUBLISHING.value]: {
        name: PROTOCOL_COMPOSITION_STATUS_COMBINED.PUBLISHING.name,
        query: {
            status: PROTOCOL_COMPOSITION_STATUS.FINAL,
            "integration-status": PROTOCOL_COMPOSITION_INTEGRATION.IN_PROGRESS,
        }
    },
    [PROTOCOL_COMPOSITION_STATUS_COMBINED.PUBLISHED.value]: {
        name: PROTOCOL_COMPOSITION_STATUS_COMBINED.PUBLISHED.name,
        query: {
            status: PROTOCOL_COMPOSITION_STATUS.FINAL,
            "integration-status": PROTOCOL_COMPOSITION_INTEGRATION.COMPLETE,
        }
    }
};

let STATUSES = {...STATUSES_DEFAULT};

/**
 * конвертер типа страницы в тип заявки
 */
const TYPE_TO_VALUE = {
    f_in_work: "in_work",
    f_to_sign: "to_sign",
    f_ready: "completed",
};

export class StatusFilter {

    /**
     * ключ статуса заявки
     */
    @observable value = "";

    constructor(dataType = "") {
        if (CustomVersion.protocolElectronicSignMode === PROTOCOL_ELECTRONIC_SIGN_MODE.SINGLE){
            STATUSES = {...STATUSES, ...STATUSES_SINGLE};
        }
        else if (CustomVersion.protocolElectronicSignMode === PROTOCOL_ELECTRONIC_SIGN_MODE.MULTIPLE){
            STATUSES = {...STATUSES, ...STATUSES_MULTIPLE};
        }
        if(Object.keys(TYPE_TO_VALUE).includes(dataType)) {
            this.value = TYPE_TO_VALUE[dataType];
        }
    }

    /**
     * данные для поиска по статусу
     */
    get query() {
        return _get(STATUSES, [this.value, 'query']);
    }

    /**
     * выбранное значение статуса заявки для React-Select
     */
    get option() {
        return this.options.find(option => option.value == this.value) || "";
    }

    /**
     * возможные значения статуса заявки для React-Select
     */
    get options() {
        return Object.keys(STATUSES).map(key => ({
            value: key,
            label: <EOLocale.Text id={_get(STATUSES, [key, 'name'])}/>
        }));
    }

    /**
     * установка значения статуса заявки
     */
    setOption = (selected) => {
        if(selected) {
            const value = _get(selected, "value");
            if(Object.keys(STATUSES).includes(value)) {
                this.value = value;
            }
        } else {
            this.value = ""
        }
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
