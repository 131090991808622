import moment from "moment";
import { Day } from "./Day";

/**
 * дни на календаре за выбранный период
 */
export class Days extends Array {

    constructor(firstDate, lastDate, todayDate, selectedDate, setSelectedDate) {
        const days = [];
        const date = moment(firstDate);
        while( date.isSameOrBefore(lastDate) ) {
            days.push(new Day(date.clone(), todayDate, selectedDate, setSelectedDate));
            date.add(1, "day");
        }
        super(...days);
    }

}
