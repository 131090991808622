import _get from "lodash/get";
import { Item } from "./Item";
import {MANUAL_EXTENSION} from "MODEL_STORE/DataSource/Constants/participant_urls";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";

/**
 * массив с информацией о консилиумах
 */
export class Items extends Array {
    /**
     * на входе - ответ от сервера
     */
    constructor(response) {
        const collection = [];
        response.filterResources("Appointment").forEach(appointment => {
            const item = new Item(appointment);
            let isParticipant = false;
            _get(appointment, "participant", []).forEach(participant => {
                const resourceId = _get(participant, "actor.id");
                const resourceType = _get(participant, "actor.resourceType");
                if( resourceType == "Location") {
                    const location = response.findResource("Location", resourceId);
                    if( ! location ) {
                        return console.warn(`Не найден ресурс места проведения консилиума ${appointment.id}`);
                    }
                    item.setLocation(location);
                    const organization = response.findResource("Organization", _get(location, "managingOrganization.id"));
                    if( ! organization ) {
                        return console.warn(`Не найден ресурс организации места проведения ${location.id} консилиума ${appointment.id}`);
                    }
                    item.setOrganization(organization);
                } else if ( resourceType == "PractitionerRole" || _get(participant, "actor.extension", []).find(j => j.url === MANUAL_EXTENSION.PRACTITIONER_URL)) {
                    const role = response.findResource("PractitionerRole", resourceId);
                    const practitioner = response.findResource("Practitioner", _get(role, "practitioner.id"));
                    item.setParticipant(participant, role, practitioner);
                    if ( resourceId == AuthStore.currentUserData.active_role_id) isParticipant = true;
                }
            });
            item.setConditions(response.filterResourcesByReferences("Condition", _get(appointment, "reasonReference", [])));
            item.setCarePlans(response.filterResourcesByReferences("CarePlan", _get(appointment, "supportingInformation", [])));
            const slots = response.filterResourcesByReferences("Slot", _get(appointment, "slot", []));
            const serviceRequests = response.filterResourcesByReferences("ServiceRequest", _get(appointment, "basedOn", []));
            item.setSlotsAndServiceRequests(slots, serviceRequests);
            if ( item.slotCounters.own > 0 || isParticipant ) {
                collection.push(item);
            }
        });
        super(...collection);
    }

}
