import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import AuthorPostIcon from "GUI_MAIN/icons/AuthorPostIcon";

const AuthorPost = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.show ? 'auto' : '32px'};
  min-height: 32px;
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.normalFontSize};
  line-height: 22px;
  overflow: hidden;
  background-color: rgb(95%, 79%, 30%, .25);
  padding: 5px 5px 0 46px;
`;

const AuthorPostIconWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 21px;
  top: 5px;
`;

@observer
export class AppointmentComment extends React.PureComponent {
    render() {
        const {
            show,
            comment
        } = this.props;
        if( ! comment ) {
            return null;
        }
        return (
            <AuthorPost show={show}>
                <AuthorPostIconWrapper>
                    <AuthorPostIcon/>
                </AuthorPostIconWrapper>
                {comment}
            </AuthorPost>
        )
    }
}
