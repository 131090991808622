import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {EOLocale, Translator} from "eo-locale";
import {lang_ru} from "Locale/languages/ru";
import {locales} from "Locale";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";

const PractitionerCardWrapper = styled.div`
  padding: 8px 12px;
  border-radius: 2px;
  border: 1px solid #F0F1F3;
  width: 100%;
  min-height: 68px;
`;

const NameAndPosition = styled.div`
  color: ${props=>props.theme.darkMainTextColor};
`;

const Name = styled.div`
  font-family: ${props=>props.theme.OpenSansSemiBold};
  font-size: ${props=>props.theme.mediumFontSize};
  line-height: 16px;
  margin-bottom: 4px;
`;

const Position = styled.div`
  font-size: ${props => props.theme.normalFontSize};
  line-height: 16px;
  font-family: ${props=>props.theme.OpenSansRegular};
`;

class PractitionerCard extends PureComponent {

    roles = (rol) => {
       switch (rol) {
           case 'Председатель' :
               return 'П';
           case 'Секретарь':
               return 'C';
           case 'Эксперт':
               return 'Э';
           case 'Консультант':
               return 'К';
           default:
               return 'К';
       }
    };

    initials = (name) => {
        if(name==='Не назначен')
            return '?';
        const data = name.split(' ');
        const length = (data.length) > 3 ? 3 : data.length;
        switch (length) {
            case 3:
                return data[1][0] + data[2][0];
            case 2:
                return data[0][0] + data[1][0];
            case 1:
                return data[0][0];
            default:
                return '?'
        }
    };

    render() {
        const { name, position, roles} = this.props;
        const translator = new Translator(HeaderStore.locale, locales);
        console.log(name);
        console.log([position]);
        return(
            <PractitionerCardWrapper>
                <NameAndPosition>
                    <Name>{name === 'Не назначен' ? translator.translate('SS9035836') : name}</Name>
                    <Position>
                        {
                            name === 'Не назначен' ?
                                translator.translate('SS9035836')
                            :
                                lang_ru.messages[position] ?
                                    <EOLocale.Text id={position}/>
                                :
                                    position
                        }
                    </Position>
                </NameAndPosition>
            </PractitionerCardWrapper>
        )
    }
}

PractitionerCard.propTypes = {
    name: PropTypes.string,
    position: PropTypes.string,
    roles: PropTypes.string,
    show: PropTypes.bool,
};

PractitionerCard.defaultProps = {
    show: false,
};

export default PractitionerCard;
