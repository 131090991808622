import moment from 'moment';
import { observable } from 'mobx';
import { getFormat } from "Locale/datetime";

export class PeriodFilter {

    /**
     * даты проведения консилиумов
     */
    @observable dates = {
        after: moment().add(-14, 'days').startOf("day").format(getFormat("DT000006")),
        before: moment().endOf("day").format(getFormat("DT000006")),
    }

    /**
     *
     */
    constructor(dataType = "") {
        if(dataType == "f_archive") {
            this.dates = {
                after: moment().subtract(1, 'year').format(getFormat("DT000006")),
                before: moment().format(getFormat("DT000006"))
            }
        }
    }

    /**
     * дата проведения первого консилиума
     */
    get afterDate() {
        return moment(this.dates.after);
    }

    /**
     * дата проведения последнего консилиума
     */
    get beforeDate() {
        return moment(this.dates.before);
    }

    /**
     * установка дат
     */
    setDates = (firstDate, secondDate) => {
        this.dates = {
            after: moment.min(firstDate, secondDate).startOf('day').format(getFormat("DT000006")),
            before: moment.max(firstDate, secondDate).endOf('day').format(getFormat("DT000006")),
        }
        this.setLocalStorage();
    }
    
    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }
    
    setLocalStorage = () => {
        let filters = localStorage.getItem(this.localStoreFieldsKey);
        if (filters) {
            filters = JSON.parse(filters);
        } else {
            filters = {};
        }
        filters[this.paramKey] = this.dates;
        localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
    }
}
