import {ServiceRequest} from "MODEL_STORE/FHIR/Resources/ServiceRequest";
import {COMPOSITION_STATUS} from "MODEL_STORE/FHIR/CodeSystem/CompositionStatus";
import {REQUEST_STATUS} from "MODEL_STORE/FHIR/CodeSystem/RequestStatus";
import {REQUEST_INTENT} from "MODEL_STORE/FHIR/CodeSystem/RequestIntent";
import {REQUEST_STATUS_COMBINED} from "./const";
import {basicRequestPost} from "MODEL_STORE/DataSource/Requests/basicRequestPost";


export class CustomServiceRequest extends ServiceRequest {

    //определение статуса заявки
    checkStatusrequest = (compositions, errorCheckStatus, breakComposition = false) => {
        if (!errorCheckStatus)
            errorCheckStatus = this.defaultOnErrorCheckStatus;

        if (!breakComposition) {
            //Определяем статус композишенов
            let statusArray = {
                preliminary: 0,
                final: 0,
                amended: 0,
                enteredInError: 0
            };

            compositions.forEach(i => {
                if (!i) return;
                if (i.status === COMPOSITION_STATUS.PRELIMINARY)
                    statusArray.preliminary++;
                if (i.status === COMPOSITION_STATUS.FINAL)
                    statusArray.final++;
                if (i.status === COMPOSITION_STATUS.AMENDED)
                    statusArray.amended++;
                if (i.status === COMPOSITION_STATUS.ENTERED_IN_ERROR)
                    statusArray.enteredInError++;
            });
            let compositionsStatus = "";
            if (statusArray.preliminary === compositions.length)
                compositionsStatus = COMPOSITION_STATUS.PRELIMINARY;
            if (statusArray.final === compositions.length)
                compositionsStatus = COMPOSITION_STATUS.FINAL;
            if (statusArray.amended === compositions.length)
                compositionsStatus = COMPOSITION_STATUS.AMENDED;
            if (statusArray.enteredInError === compositions.length)
                compositionsStatus = COMPOSITION_STATUS.ENTERED_IN_ERROR;

            if (compositionsStatus === "")
                return errorCheckStatus(this._data.id);
        }
        switch(this._data.status) {
            case REQUEST_STATUS.DRAFT:
                if (this._data.intent === REQUEST_INTENT.PROPOSAL)
                    return REQUEST_STATUS_COMBINED.CREATED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.ENTERED_IN_ERROR:
                if (this._data.intent === REQUEST_INTENT.PROPOSAL)
                        return REQUEST_STATUS_COMBINED.DELETED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.ON_HOLD:
                if (this._data.intent === REQUEST_INTENT.PLAN)
                        return REQUEST_STATUS_COMBINED.MATCHING;
                else if (this._data.intent === REQUEST_INTENT.PROPOSAL)
                        return REQUEST_STATUS_COMBINED.BREAK;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.REVOKED:
                if (this._data.intent === REQUEST_INTENT.PROPOSAL || this._data.intent === REQUEST_INTENT.PLAN || this._data.intent === REQUEST_INTENT.ORDER)
                        return REQUEST_STATUS_COMBINED.CANCELED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.ACTIVE:
                if (this._data.intent === REQUEST_INTENT.ORDER || this._data.intent === REQUEST_INTENT.PLAN)
                        return REQUEST_STATUS_COMBINED.ASSIGNED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.COMPLETED:
                if (this._data.intent === REQUEST_INTENT.ORDER)
                        return REQUEST_STATUS_COMBINED.FINISHED;
                else
                    return errorCheckStatus(this._data.id);

            default:
                return errorCheckStatus(this._data.id);
        }
    };

    //определение статуса заявки без использования composition
    checkStatusRequestWithoutComposition = (errorCheckStatus) => {
        if (!errorCheckStatus)
            errorCheckStatus = this.defaultOnErrorCheckStatus;

        switch(this._data.status) {
            case REQUEST_STATUS.DRAFT:
                if (this._data.intent === REQUEST_INTENT.PROPOSAL)
                    return REQUEST_STATUS_COMBINED.CREATED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.ENTERED_IN_ERROR:
                if (this._data.intent === REQUEST_INTENT.PROPOSAL)
                        return REQUEST_STATUS_COMBINED.DELETED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.ON_HOLD:
                if (this._data.intent === REQUEST_INTENT.PLAN)
                        return REQUEST_STATUS_COMBINED.MATCHING;
                else if (this._data.intent === REQUEST_INTENT.PROPOSAL)
                        return REQUEST_STATUS_COMBINED.BREAK;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.REVOKED:
                if (this._data.intent === REQUEST_INTENT.PROPOSAL || this._data.intent === REQUEST_INTENT.PLAN || this._data.intent === REQUEST_INTENT.ORDER)
                        return REQUEST_STATUS_COMBINED.CANCELED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.ACTIVE:
                if (this._data.intent === REQUEST_INTENT.ORDER || this._data.intent === REQUEST_INTENT.PLAN)
                        return REQUEST_STATUS_COMBINED.ASSIGNED;
                else
                    return errorCheckStatus(this._data.id);

            case REQUEST_STATUS.COMPLETED:
                if (this._data.intent === REQUEST_INTENT.ORDER)
                        return REQUEST_STATUS_COMBINED.FINISHED;
                else
                    return errorCheckStatus(this._data.id);

            default:
                return errorCheckStatus(this._data.id);
        }
    };

    //Можно ли просматривать заявку
    canLook = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        return status === REQUEST_STATUS_COMBINED.CANCELED ||
            status === REQUEST_STATUS_COMBINED.DELETED ||
            status === REQUEST_STATUS_COMBINED.FINISHED ||
            status === REQUEST_STATUS_COMBINED.MATCHING ||
            status === REQUEST_STATUS_COMBINED.ASSIGNED;
    };

    // Можно ли проставить "заявка готова к консилиуму" (когда консилиум без согласования)
    canReadyToAppointment = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        const result = status === REQUEST_STATUS_COMBINED.CREATED || status === REQUEST_STATUS_COMBINED.BREAK;

        // вот так отлаживать гораздо легче
        return result;
    };

    // Можно ли принять заявку
    canAccept = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        const result = status === REQUEST_STATUS_COMBINED.MATCHING;
        return result;
    };

    //Можно ли аннулировать
    canWithdraw = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        return status === REQUEST_STATUS_COMBINED.MATCHING ||
            status === REQUEST_STATUS_COMBINED.ASSIGNED;
    };

    //Можно ли закрыть
    canFinished = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        return status === REQUEST_STATUS_COMBINED.ASSIGNED;
    };

    //Можно ли перейцти к протоколу
    canSeeProtocol = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        return status === REQUEST_STATUS_COMBINED.FINISHED;
    };

    //Можно ли отправить на согласование
    canApproval = (compositions) => {
        const status = this.checkStatusrequest(compositions);
        return status === REQUEST_STATUS_COMBINED.CREATED ||
            status === REQUEST_STATUS_COMBINED.BREAK;
    };

    // Не используются
    // approval = () => {
    //     this.status = REQUEST_STATUS.ON_HOLD;
    //     this.intent = REQUEST_INTENT.PLAN;
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "need-approval"});
    // };
    //
    // readyToAppointment = () => {
    //     this._data = {
    //         ...this._data,
    //         status: REQUEST_STATUS.ACTIVE,
    //         intent: REQUEST_INTENT.ORDER
    //     };
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "active"});
    // };
    //
    // BackReadyToAppointment = () => {
    //     this._data = {
    //         ...this._data,
    //         status: REQUEST_STATUS.ACTIVE,
    //         intent: REQUEST_INTENT.ORDER
    //     };
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "back_to_active"});
    // };
    //
    // BackToWork = () => {
    //     this._data = {
    //         ...this._data,
    //         status: REQUEST_STATUS.ON_HOLD,
    //         intent: REQUEST_INTENT.PROPOSAL
    //     };
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "back_to_work"});
    // };
    //
    // finished = () => {
    //     this.status = REQUEST_STATUS.COMPLETED;
    //     this.intent = REQUEST_INTENT.ORDER;
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "completed"});
    // };
    //
    // withdraw = () => {
    //     this.status = REQUEST_STATUS.REVOKED;
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "revoked"});
    // };
    //
    // reject = (extension) => {
    //     // исключаем промежуточный ренедер с проверкой статусов без одновременной установки status и intent
    //     this._data = {
    //         ...this._data,
    //         status: REQUEST_STATUS.ON_HOLD,
    //         intent: REQUEST_INTENT.PROPOSAL
    //     };
    //     this._data.extension = extension;
    //     return basicRequestPost(`/api/mm/service-requests/${this.id}/status`, {status: "rejected", "reject-reason": extension[0].valueCodeableConcept.text});
    // };
}
